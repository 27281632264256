import type { RouteRecordRaw } from 'vue-router';
import { isAuthenticated } from '~/router/middleware/is-authenticated';
import { isInfluencer } from '~/router/middleware/is-influencer';
import { getChatRoutes } from '~/shared/chat/chat.routes';
import type { GetChildrenRouteNames } from '~/types';

export const influencerRoutes = [
  {
    path: '/influencer',
    name: 'influencer' as const,
    component: () => import('~/scopes/influencer/InfluencerView'),
    beforeEnter: [isAuthenticated(), isInfluencer()],
    redirect: {
      name: 'influencer.dashboard',
    },
    children: [
      ...getChatRoutes('influencer'),
      {
        path: 'dashboard',
        name: 'influencer.dashboard' as const,
        component: () => import('~/scopes/influencer/features/dashboard/views/DashboardView'),
      },
      {
        path: 'social-channels',
        name: 'creator.social-channels' as const,
        component: () =>
          import('~/scopes/influencer/features/social-channels/views/SocialChannelsView'),
      },
      {
        path: 'social-channels/:channelId/instagram',
        name: 'influencer.social-channel.instagram' as const,
        component: () => import('~/scopes/influencer/features/social-channels/views/InstagramView'),
      },
      {
        path: 'collaborations',
        name: 'influencer.collaborations' as const,
        component: () =>
          import('~/scopes/influencer/features/collaborations/views/CollaborationsView').then(
            (module) => module.CollaborationsView
          ),
      },
      {
        path: 'discover',
        children: [
          {
            path: '',
            name: 'influencer.discover' as const,
            redirect: {
              name: 'influencer.discover.campaigns',
            },
            /* component: () =>
              import('~/scopes/influencer/features/discover/views/DiscoverView').then(
                (module) => module.DiscoverView
              ), */
          },
          {
            path: 'brands',
            name: 'influencer.discover.brands' as const,
            component: () =>
              import('~/scopes/influencer/features/brands/views/BrandsView').then(
                (module) => module.BrandsView
              ),
          },
          {
            path: 'campaigns',
            name: 'influencer.discover.campaigns' as const,
            component: () =>
              import('~/scopes/influencer/features/campaigns/views/CampaignsView').then(
                (module) => module.CampaignsView
              ),
          },
        ],
      },
      {
        path: 'reports',
        name: 'influencer.reports' as const,
        redirect: {
          name: 'influencer.reports.summary',
        },
        component: () =>
          import('~/scopes/influencer/features/reports/views/ReportsView').then(
            (module) => module.ReportsView
          ),
        children: [
          {
            path: 'summary',
            name: 'influencer.reports.summary' as const,
            component: () =>
              import('~/scopes/influencer/features/reports/views/SummaryView').then(
                (module) => module.SummaryView
              ),
          },
          {
            path: 'daily',
            name: 'influencer.reports.daily' as const,
            component: () =>
              import('~/scopes/influencer/features/reports/views/DailyView').then(
                (module) => module.DailyView
              ),
          },
          {
            path: 'sales',
            name: 'influencer.reports.sales' as const,
            component: () =>
              import('~/scopes/influencer/features/reports/views/SalesView').then(
                (module) => module.SalesView
              ),
          },
        ],
      },
      {
        path: 'campaigns/:campaignId',
        name: 'influencer.campaign' as const,
        component: () =>
          import('~/scopes/influencer/features/campaign/views/CampaignView').then(
            (module) => module.CampaignView
          ),
      },
      {
        path: 'brands/:brandId',
        name: 'influencer.brand' as const,
        component: () =>
          import('~/scopes/influencer/features/brand/views/BrandView').then(
            (module) => module.BrandView
          ),
      },
      {
        path: 'payout-account',
        name: 'influencer.payout-account' as const,
        component: () =>
          import('~/scopes/influencer/features/payout-account/views/PayoutAccountView').then(
            (module) => module.PayoutAccountView
          ),
      },
      {
        path: 'promotion-codes',
        name: 'influencer.promotion-codes' as const,
        component: () =>
          import('~/scopes/influencer/features/promotion-codes/views/PromotionCodesView').then(
            (module) => module.PromotionCodesView
          ),
      },
      {
        path: 'user-settings',
        component: () =>
          import(
            '~/scopes/influencer/features/user-settings/views/InfluencerUserSettingsView'
          ).then((module) => module.InfluencerUserSettingsView),
        children: [
          {
            path: '',
            name: 'influencer.user-settings' as const,
            component: () =>
              import('~/shared/user/views/UserSettingsProfileView').then(
                (module) => module.UserSettingsProfileView
              ),
          },
          {
            path: 'password',
            name: 'influencer.user-settings.password' as const,
            component: () =>
              import('~/shared/user/views/UserSettingsPasswordView').then(
                (module) => module.UserSettingsPasswordView
              ),
          },
          {
            path: 'notifications',
            name: 'influencer.user-settings.notifications' as const,
            component: () =>
              import('~/scopes/influencer/features/user-settings/views/NotificationsView').then(
                (module) => module.NotificationsView
              ),
          },
          {
            path: 'profile-visibility',
            name: 'influencer.user-settings.profile-visibility' as const,
            component: () =>
              import('~/scopes/influencer/features/user-settings/views/ProfileVisibilityView').then(
                (module) => module.ProfileVisibilityView
              ),
          },
        ],
      },
      {
        path: 'payouts',
        name: 'influencer.payouts' as const,
        component: () =>
          import('~/scopes/influencer/features/payouts/views/PayoutsView').then(
            (module) => module.PayoutsView
          ),
      },
    ],
  },
] satisfies RouteRecordRaw[];

type RouteNames = GetChildrenRouteNames<typeof influencerRoutes>;

export const influencerMenuRoutes = {
  mobile: ['influencer.dashboard', 'influencer.discover', 'influencer.collaborations', 'chats'],
  desktop: ['influencer.dashboard', 'influencer.discover', 'influencer.collaborations'],
  overlay: [
    'creator.social-channels',
    'influencer.promotion-codes',
    'influencer.payout-account',
    'influencer.reports',
    'influencer.payouts',
    'influencer.user-settings',
  ],
  menu: [
    'creator.social-channels',
    'influencer.promotion-codes',
    'influencer.payout-account',
    'influencer.reports',
    'influencer.payouts',
  ],
} satisfies Record<'mobile' | 'desktop' | 'overlay' | 'menu', RouteNames[]>;
