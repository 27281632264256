import type { V1Schema } from '@makeinfluence/graphql-schema/types';
import { cacheKeys } from '@makeinfluence/ui/src/utils/cache-keys';
import type { z } from 'zod';
import type { chatTypeSchema } from '~/shared/chat/chat.store';

export const checkAuthKeys = cacheKeys({
  prefix: ['checkAuth'],
  keyConfig: {
    status: [],
  },
});

export const chatKeys = cacheKeys({
  prefix: ['chat'],
  keyConfig: {
    list: (params: { type: z.infer<typeof chatTypeSchema>; search?: string; limit: number }) => [
      params,
    ],
    messages: (params: { channelUrl: string }) => [params],
    unreadCounts: (params: { accountType: string }) => [params],
    new: {
      search: (params: { keywords: string }) => [params],
    },
  },
});

export const accountKeys = cacheKeys({
  prefix: ['account'],
  keyConfig: {
    list: [],
    ids: [],
  },
});

export const meKeys = cacheKeys({
  prefix: ['me'],
  keyConfig: {
    details: [],
  },
});

export const mobileMenuKeys = cacheKeys({
  prefix: ['mobileMenu'],
  keyConfig: {
    me: [],
    account: [],
  },
});

export const createCampaignKeys = cacheKeys({
  prefix: ['createCampaign'],
  keyConfig: {
    business: (params: { businessId: string }) => [params],
  },
});

export const userKeys = cacheKeys({
  prefix: ['user'],
  keyConfig: {
    settings: {
      profile: [],
      notifications: [],
    },
  },
});

export const channelCategoriesKeys = cacheKeys({
  prefix: ['channelCategories'],
  keyConfig: {
    list: [],
  },
});

export const countriesKeys = cacheKeys({
  prefix: ['countries'],
  keyConfig: {
    list: [],
  },
});

export const systemSettingsKeys = cacheKeys({
  prefix: ['systemSettings'],
  keyConfig: {
    details: [],
  },
});

export const stripeKeys = cacheKeys({
  prefix: ['stripe'],
  keyConfig: {
    load: [],
  },
});

export const searchKeys = cacheKeys({
  prefix: ['search'],
  keyConfig: {
    brand: (params: V1Schema.QueryBusinessesArgs) => [params],
    campaign: (params: V1Schema.QueryCampaignsArgs) => [params],
    influencer: (params: V1Schema.QueryInfluencersArgs) => [params],
    countries: (params: V1Schema.QueryCountriesArgs) => [params],
  },
});

export const signupKeys = cacheKeys({
  prefix: ['signup'],
  keyConfig: {
    business: [],
    creator: [],
  },
});

export const loginKeys = cacheKeys({
  prefix: ['login'],
  keyConfig: {
    user: [],
  },
});
