import { useQuery } from '@tanstack/vue-query';
import { retry } from 'radash';
import { graphql } from '~/graphql';
import { client } from '~/plugins/client';

export async function fetchStripeDetails() {
  const [hasStripeIdResult, subscriptionResult] = await Promise.allSettled([
    retry({ times: 3 }, async (exit) => {
      try {
        const response = await client.request({
          document: graphql(`
            query HasStripeId {
              me {
                stripe_id
              }
            }
          `),
        });

        return !!response.me?.stripe_id;
      } catch (error) {
        exit(error);
      }
    }),
    retry({ times: 3 }, async (exit) => {
      try {
        const response = await client.request({
          document: graphql(`
            query GetActiveSubscription {
              getActiveSubscription {
                ends_at
                has_scheduled_change
                is_active
                is_canceled
                is_trialing
                product {
                  name
                }
                trial_ends_at
              }
            }
          `),
        });

        return response.getActiveSubscription;
      } catch (error) {
        exit(error);
      }
    }),
  ]);

  const hasStripeId = hasStripeIdResult.status === 'fulfilled' ? !!hasStripeIdResult.value : false;
  const activeSubscription =
    subscriptionResult.status === 'fulfilled' ? subscriptionResult.value : null;
  const hasActiveSubscription = !!activeSubscription?.is_active;
  const hasCanceledSubscription = !!activeSubscription?.is_canceled;

  return {
    activeSubscription,
    hasActiveSubscription,
    hasCanceledSubscription,
    hasStripeId,
  };
}

export function useStripeDetailsQuery() {
  const stripeDetailsQuery = useQuery({
    queryKey: ['stripe-details'],
    queryFn: fetchStripeDetails,
  });

  return stripeDetailsQuery;
}
