import { useMutation } from '@tanstack/vue-query';
import { useRouter } from 'vue-router';
import { graphql } from '~/graphql';
import { client } from '~/plugins/client';
import { useAuthenticationStore } from '~/stores/authentication';

export async function logout() {
  const response = await client.request({
    document: graphql(`
      mutation Logout {
        logout {
          status
        }
      }
    `),
  });

  return response.logout;
}

export function useLogoutMutation() {
  const authenticationStore = useAuthenticationStore();
  const router = useRouter();

  const mutation = useMutation({
    mutationFn: async () => {
      const response = logout();

      authenticationStore.clearAuth();
      router.push({ name: 'public.home' });

      return await response;
    },
  });

  return mutation;
}
