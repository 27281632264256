import { router } from '~/router/routes';
import { PiniaDebounce } from '@pinia/plugin-debounce';
import { debounce } from 'lodash-es';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { markRaw } from 'vue';
import type { Router } from 'vue-router';

declare module 'pinia' {
  export interface PiniaCustomProperties {
    router: Router;
  }
}

export const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);
pinia.use(PiniaDebounce(debounce));
pinia.use(({ store }) => {
  store.router = markRaw(router);
});
