import { LoadingIcon } from '@makeinfluence/icons/vue/outline';
import type { ClassValue, VariantProps } from 'tailwind-variants';
import { tv } from 'tailwind-variants';
import { P, match } from 'ts-pattern';
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import type { RouteLocationNormalizedLoaded, RouteLocationRaw } from 'vue-router';
import { RouterLink } from 'vue-router';
import { LoadingShimmer } from '../LoadingShimmer';

export const button = tv({
  base: 'inline-flex flex-shrink-0 appearance-none items-center justify-center whitespace-nowrap font-medium transition focus-visible:z-[2] focus-visible:outline-none disabled:cursor-not-allowed group-focus:z-[2] group-focus:outline-none aria-disabled:pointer-events-none aria-disabled:cursor-not-allowed',
  variants: {
    variant: {
      black:
        'bg-black-tint-1 text-white shadow-sm ring-gray-200 hover:bg-black-tint-2 active:bg-gray-700 disabled:bg-gray-100 disabled:text-gray-400 aria-disabled:bg-gray-100 aria-disabled:text-gray-400',
      white:
        'border border-solid border-gray-200 bg-white text-black shadow-sm ring-gray-300 hover:bg-gray-50 focus-visible:border-black active:bg-gray-100 disabled:border-gray-100 disabled:bg-white disabled:text-gray-400 group-focus:border-black aria-disabled:border-gray-100 aria-disabled:bg-white aria-disabled:text-gray-400',
      gray: 'bg-gray-100 text-black ring-gray-300 hover:bg-gray-200 active:bg-gray-300 disabled:bg-gray-100 disabled:text-gray-300 aria-disabled:bg-gray-100 aria-disabled:text-gray-300',
      danger:
        'bg-red-500 text-white shadow-sm ring-red-300 hover:bg-red-600 active:bg-red-700 disabled:bg-red-500 disabled:text-red-300 aria-disabled:bg-red-500 aria-disabled:text-red-300',
    },

    size: {
      xs: 'text-sm focus-visible:ring-[3px] group-focus:ring-[3px]',
      sm: 'text-base focus-visible:ring-4 group-focus:ring-4',
      base: 'text-lg focus-visible:ring-4 group-focus:ring-4',
      lg: 'text-lg focus-visible:ring-4 group-focus:ring-4',
    },

    shape: {
      rounded: 'rounded-lg',
      icon: 'rounded-full',
    },

    state: {
      selected: [
        /**
         * When used in a button group, the selected button's border
         * should stay on top of the other buttons' borders.
         */
        'z-[1] border-gray-600 bg-gray-50 hover:bg-gray-50',
      ],
      loading: '',
    },

    isLink: {
      true: '',
    },
    disabled: {
      true: '',
    },
  },

  compoundVariants: [
    {
      shape: 'icon',
      size: 'xs',
      className: 'h-6 w-6',
    },
    {
      shape: 'icon',
      size: 'sm',
      className: 'h-8 w-8',
    },
    {
      shape: 'icon',
      size: 'base',
      className: 'h-10 w-10',
    },
    {
      shape: 'icon',
      size: 'lg',
      className: 'h-12 w-12',
    },

    {
      shape: 'rounded',
      size: 'xs',
      className: 'h-7 gap-0.5 px-2',
    },
    {
      shape: 'rounded',
      size: 'sm',
      className: 'h-8 gap-1 px-3',
    },
    {
      shape: 'rounded',
      size: 'base',
      className: 'h-10 gap-1.5 px-4',
    },
    {
      shape: 'rounded',
      size: 'lg',
      className: 'h-12 gap-1.5 px-4',
    },
    {
      isLink: true,
      disabled: true,
      className: 'pointer-events-none',
    },
  ],

  defaultVariants: {
    variant: 'black',
    size: 'base',
    shape: 'rounded',
  },
});

export const icon = tv({
  base: 'inline-flex items-center justify-center',
  variants: {
    size: {
      xs: 'h-4 w-4',
      sm: 'h-5 w-5',
      base: 'h-5 w-5',
      lg: 'h-7 w-7',
    },
  },

  defaultVariants: {
    size: 'base',
  },
});

export function handleButtonState(options: {
  [K in NonNullable<VariantProps<typeof button>['state']>]?: boolean;
}) {
  return match(options)
    .with({ loading: true }, () => 'loading' as const)
    .with({ selected: true }, () => 'selected' as const)
    .otherwise(() => undefined);
}

export const MiButton = defineComponent({
  name: 'MiButton',
  inheritAttrs: false,
  props: {
    to: {
      type: [Object, String] as PropType<RouteLocationRaw | RouteLocationNormalizedLoaded | string>,
      required: false,
      default: undefined,
    },
    href: {
      type: String,
      required: false,
      default: undefined,
    },
    download: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    variant: {
      type: String as PropType<VariantProps<typeof button>['variant']>,
      required: false,
      default: 'black',
    },
    size: {
      type: String as PropType<VariantProps<typeof button>['size']>,
      required: false,
      default: 'base',
    },
    shape: {
      type: String as PropType<VariantProps<typeof button>['shape']>,
      required: false,
      default: 'rounded',
    },
    state: {
      type: String as PropType<VariantProps<typeof button>['state']>,
      required: false,
      default: undefined,
    },
    as: {
      type: [Object, String],
      required: false,
      default: undefined,
    },
    onClick: {
      type: [Function, Array] as PropType<(() => void) | (() => void)[]>,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String as PropType<'button' | 'submit' | 'reset'>,
      required: false,
      default: 'button',
    },
    form: {
      type: String,
      required: false,
      default: undefined,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  setup(props, { attrs, slots }) {
    return () => {
      const Component = match(props)
        .with({ as: P.not(P.nullish) }, () => props.as)
        .with({ to: P.not(P.nullish) }, () => RouterLink)
        .with({ href: P.not(P.nullish) }, () => 'a')
        .otherwise(() => 'button');

      const href = props.href;
      const to = props.to;
      const rel =
        typeof href === 'string' && href.startsWith('http') ? 'noopener noreferrer' : null;

      if (href && to) {
        throw new Error('Cannot use both `href` and `to` props at the same time.');
      }

      return props.loading ? (
        <LoadingShimmer
          variant="dark"
          class={button({
            shape: props.shape,
            size: props.size,
            state: props.state,
            variant: props.variant,
            className: ['pointer-events-none bg-black/5', attrs.class as ClassValue],
          })}
        >
          <span class="invisible">{slots.default?.()}</span>
        </LoadingShimmer>
      ) : (
        // @ts-expect-error JSX element type 'Component' does not have any construct or call signatures.
        <Component
          {...(Component === 'a'
            ? {
                href,
                download: props.download,
              }
            : Component === RouterLink
              ? { to }
              : null)}
          rel={rel}
          class={button({
            shape: props.shape,
            size: props.size,
            state: props.state,
            variant: props.variant,
            isLink: !!href || !!to,
            disabled: props.disabled,
            className: attrs.class as ClassValue,
          })}
          onClick={attrs.disabled ? null : props.onClick}
          disabled={props.disabled}
          aria-disabled={props.disabled}
          type={props.type}
          form={props.form}
          id={props.id}
          {...attrs}
        >
          {slots.startIcon ? (
            <span class={icon({ size: props.size })}>{slots.startIcon()}</span>
          ) : null}

          {props.state === 'loading' ? (
            <span
              class={icon({
                size: props.size,
                className: 'absolute animate-spin opacity-75',
              })}
            >
              <LoadingIcon class="size-full" />
            </span>
          ) : null}

          {props.state === 'loading' ? (
            <span class="invisible">{slots.default?.()}</span>
          ) : (
            slots.default?.()
          )}

          {slots.endIcon ? <span class={icon({ size: props.size })}>{slots.endIcon()}</span> : null}
        </Component>
      );
    };
  },
});
