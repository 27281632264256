<script lang="ts" setup>
import { RouterView } from 'vue-router';
import { SnackBar } from '~/components/SnackBar';
import { NewChatDialog } from '~/shared/chat/components/NewChatDialog';
import { useAuthenticationStore } from './stores/authentication';

const authenticationStore = useAuthenticationStore();
</script>

<template>
  <RouterView />

  <SnackBar />

  <NewChatDialog v-if="authenticationStore.isLoggedIn" />
</template>

<style lang="scss">
@import './styles/styles.scss';
</style>
