import { GraphQLClient } from 'graphql-request';
import { z } from 'zod';
import { env } from '~/env';
import { pinia } from '~/plugins/pinia';
import { useAuthenticationStore } from '~/stores/authentication';

const graphQLBodySchema = z.object({
  query: z.string(),
});

/**
 * Implements the GraphQL multipart request spec.
 *
 * @see https://github.com/jaydenseric/graphql-multipart-request-spec
 *
 * Support:
 * - Single file ✅
 * - File list ❌
 * - Batching ❌
 *
 * @param fetch The fetch function to wrap.
 * @returns A wrapped fetch function that handles GraphQL multipart requests.
 */
function createMultipartFetch(
  fetch: (resource: string | URL | globalThis.Request, init?: RequestInit) => Promise<Response>
) {
  return async (
    resource: string | URL | globalThis.Request,
    init: RequestInit & {
      variables?: Record<string, unknown>;
      operationName?: string;
    } = {}
  ) => {
    const initBody = graphQLBodySchema.parse(JSON.parse(init.body?.toString() ?? '{}'));
    const initVariables = init.variables ?? {};
    // Check if there are any File uploads
    const hasUpload = Object.values(initVariables).some((value) => {
      return value instanceof File;
    });

    if (hasUpload) {
      const operations = {
        operationName: init.operationName,
        variables: structuredClone(init.variables), // dereference/deep clone object
        query: initBody.query,
      };

      operations.variables ??= {};

      for (const [key, value] of Object.entries(operations.variables)) {
        if (value instanceof File) {
          operations.variables[key] = null;
        }
      }

      const map = Object.entries(initVariables).reduce(
        (map, [key, value], index) => {
          if (value instanceof File) {
            map[`${index}`] = [`variables.${key}`];
          }

          return map;
        },
        {} as Record<string, string[]>
      );

      const formData = new FormData();
      formData.append('operations', JSON.stringify(operations));
      formData.append('map', JSON.stringify(map));

      Object.values(initVariables).forEach((value, index) => {
        if (value instanceof File) {
          formData.append(String(index), value);
        }
      });

      const headers = new Headers(init.headers);

      return fetch(resource, {
        method: init.method,
        body: formData,
        headers: {
          Authorization: headers.get('Authorization') ?? '',
        },
      });
    }

    return fetch(resource, init);
  };
}

function getAuthHeaders() {
  const authenticationStore = useAuthenticationStore(pinia);

  const authHeaders = new Headers();

  if (authenticationStore.auth.accessToken) {
    authHeaders.set('Authorization', `Bearer ${authenticationStore.auth.accessToken}`);
  }

  return authHeaders;
}

export const client = new GraphQLClient(env.VITE_V1_GRAPHQL_ENDPOINT, {
  fetch: createMultipartFetch(window.fetch),
  requestMiddleware: (request) => {
    const authHeaders = getAuthHeaders();

    const headers = new Headers(request.headers);

    headers.set('Authorization', authHeaders.get('Authorization') ?? '');

    request.headers = headers;

    return request;
  },
  responseMiddleware: () => {
    // TODO: Handle unauthenticated responses
    /* if (response instanceof Error) {
      const authenticationStore = useAuthenticationStore(pinia)

      authenticationStore.clearAuth()
      router.push({ name: 'public.home' })
    } */
  },
});
