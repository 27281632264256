import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  TransitionRoot,
} from '@headlessui/vue';
import {
  CheckIcon,
  ChevronDownIcon,
  CloseIcon,
  LoadingIcon,
} from '@makeinfluence/icons/vue/outline';
import type { InputAutocomplete } from '@makeinfluence/types/src/inputs';
import { LoadingShimmer } from '@makeinfluence/ui/src/components/LoadingShimmer';
import { badgeButton } from '@makeinfluence/ui/src/components/new-app/mi-badge';
import {
  miSelectOption,
  miSelectOptions,
  miSelectTrigger,
} from '@makeinfluence/ui/src/components/new-app/mi-select';
import type { ClassValue } from 'tailwind-variants';
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { z } from 'zod';

export const comboboxModelValueSchema = z.string().or(z.array(z.string())).nullish();

type ComboboxOptionType = {
  value: string;
  label: string;
};

export const MiCombobox = defineComponent({
  name: 'MiCombobox',
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: false,
      default: undefined,
    },
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Array, null] as PropType<string | Array<string> | null>,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    options: {
      type: Array as PropType<Array<ComboboxOptionType>>,
      required: false,
      default: () => [],
    },
    searchQuery: {
      type: String,
      required: false,
      default: '',
    },
    nothingFoundText: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    nullable: {
      type: Boolean,
      default: false,
    },
    filterFn: {
      type: Function as PropType<
        (searchQuery: string, options: ComboboxOptionType[]) => ComboboxOptionType[]
      >,
      required: false,
      default: (searchQuery: string, options?: Array<ComboboxOptionType>) => {
        if (!options) return [];
        if (!searchQuery) return options;

        return options.filter((option) => {
          return option.label
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(searchQuery.toLowerCase().replace(/\s+/g, ''));
        });
      },
    },
    autocomplete: {
      type: String as PropType<InputAutocomplete>,
      required: false,
      default: undefined,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: {
    'update:modelValue': comboboxModelValueSchema.parse,
    'update:searchQuery': (_value: string) => true,
  },
  setup(props, context) {
    const { t } = useI18n();
    const { class: className, ...restAttrs } = context.attrs;

    return () => {
      const displayValue = (value: unknown) => {
        if (props.multiple) return '';

        const option = props.options.find((option) => option.value === value);

        if (option) {
          return option.label;
        }

        return value?.toString() ?? '';
      };

      return props.loading ? (
        <LoadingShimmer
          variant="dark"
          class={miSelectTrigger({
            className: [className as ClassValue, 'w-full bg-black/5'],
          })}
        />
      ) : (
        <>
          <Combobox
            name={props.name}
            modelValue={props.modelValue}
            onUpdate:modelValue={(value: string | string[]) => {
              context.emit('update:modelValue', value);
            }}
            multiple={props.multiple}
            nullable={props.nullable}
            {...restAttrs}
          >
            <div class="relative">
              <ComboboxButton
                class={miSelectTrigger({
                  className: ['w-full justify-between', className as ClassValue],
                })}
                id={props.id}
              >
                <ComboboxInput
                  class="w-full truncate py-2 focus:outline-none"
                  onChange={(event: Event & { target: HTMLInputElement }) => {
                    context.emit('update:searchQuery', event.target.value);
                  }}
                  displayValue={props.options.length > 0 ? displayValue : () => props.searchQuery}
                  // @ts-expect-error placeholder is not a valid prop
                  placeholder={props.placeholder}
                />

                <span class="inline-flex items-center gap-1">
                  <CloseIcon
                    class="mt-px size-4 shrink-0 text-gray-400"
                    onClick={() => {
                      context.emit('update:modelValue', null);
                      context.emit('update:searchQuery', '');
                    }}
                  />

                  {props.isLoading ? (
                    <LoadingIcon class="mt-px size-4 shrink-0 animate-spin text-gray-400" />
                  ) : (
                    <ChevronDownIcon class="mt-px size-4 shrink-0 text-gray-400" />
                  )}
                </span>
              </ComboboxButton>

              <TransitionRoot
                enter="grid transition-[grid-template-rows] ease-in-out duration-200 motion:reduce:duration-0"
                enterFrom="motion-safe:grid-rows-[0fr]"
                enterTo="motion-safe:grid-rows-[1fr]"
                leave="grid transition-[grid-template-rows] ease-in-out duration-100 motion:reduce:duration-0"
                leaveFrom="opacity-100 motion-safe:grid-rows-[1fr]"
                leaveTo="opacity-0 motion-safe:grid-rows-[0fr]"
                as="template"
              >
                <div class="overflow-hidden">
                  <ComboboxOptions
                    class={miSelectOptions({
                      inline: props.inline,
                      className: [
                        'left-0 z-10 mt-1.5 w-full',
                        props.isLoading && props.options.length < 1 ? 'hidden' : '',
                      ],
                    })}
                  >
                    {props.filterFn(props.searchQuery, props.options).length < 1 ? (
                      <ComboboxButton class="relative w-full cursor-default select-none px-4 py-2 text-gray-700">
                        {props.nothingFoundText}
                      </ComboboxButton>
                    ) : null}

                    {props.filterFn(props.searchQuery, props.options).map((option) => (
                      <ComboboxOption
                        key={option.value}
                        value={option.value}
                        class={miSelectOption({
                          className: 'relative',
                        })}
                      >
                        <span class="block truncate ui-selected:font-semibold ui-not-selected:font-normal">
                          {option.label}
                        </span>

                        <span class="absolute inset-y-0 right-0 hidden items-center pr-3 ui-selected:inline-flex">
                          <CheckIcon class="h-5 w-5" />
                        </span>
                      </ComboboxOption>
                    ))}
                  </ComboboxOptions>
                </div>
              </TransitionRoot>
            </div>
          </Combobox>

          {props.multiple && Array.isArray(props.modelValue) && props.modelValue.length > 0 ? (
            <div class="mt-1 flex flex-wrap items-center gap-1">
              {props.modelValue.map((value, index) => {
                const option = props.options.find((o) => o.value === value);

                if (!option) return;

                return (
                  <span key={option.value}>
                    <button
                      type="button"
                      class={badgeButton({
                        class: 'gap-1 pr-1.5',
                      })}
                      onClick={() => {
                        if (!Array.isArray(props.modelValue)) return;

                        context.emit(
                          'update:modelValue',
                          props.modelValue.filter((_, i) => i !== index) as string[]
                        );
                      }}
                    >
                      <span>{option.label}</span>

                      <CloseIcon class="w-3" />
                    </button>
                  </span>
                );
              })}

              {props.options.length > 0 && (
                <button
                  type="button"
                  onClick={() => {
                    context.emit('update:modelValue', []);
                  }}
                  class={badgeButton({
                    class: 'gap-1 pr-1.5',
                  })}
                >
                  <span>{t('words--clear_all')}</span>

                  <CloseIcon class="w-3" />
                </button>
              )}
            </div>
          ) : null}
        </>
      );
    };
  },
});
