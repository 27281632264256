import type { V1Schema } from '@makeinfluence/graphql-schema/types';
import { useQuery } from '@tanstack/vue-query';
import { debouncedRef } from '@vueuse/core';
import type { MaybeRef, PropType } from 'vue';
import { computed, defineComponent, ref, unref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { searchKeys } from '~/cache.keys';
import { MiCombobox, comboboxModelValueSchema } from '~/components/MiCombobox';
import { graphql } from '~/graphql';
import { client } from '~/plugins/client';

export function useBrandSearchQuery(
  search: MaybeRef<string>,
  options: Omit<V1Schema.QueryBusinessesArgs, 'search'> & {
    enabled?: MaybeRef<boolean>;
  } = {}
) {
  const brandsQuery = useQuery({
    enabled: options.enabled ?? true,
    keepPreviousData: true,
    queryKey: computed(() => {
      return searchKeys.brand.toKeyWithArgs({
        search: unref(search),
        limit: 20,
        page: 1,
        ...options,
      });
    }),
    queryFn: async ({ signal, queryKey }) => {
      const variables = searchKeys.brand.toArgsFromKey(queryKey);

      const response = await client.request({
        document: graphql(`
          query BrandSearchQuery($search: String, $limit: Int, $page: Int) {
            businesses(search: $search, limit: $limit, page: $page) {
              ... on BusinessesPaginated {
                response {
                  uuid
                  display_name
                  user {
                    full_name
                  }
                }
              }
            }
          }
        `),
        variables,
        signal,
      });

      return response.businesses;
    },
  });

  return brandsQuery;
}

export const BrandSearch = defineComponent({
  name: 'BrandSearch',
  props: {
    id: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Array, null] as PropType<string | string[] | null>,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    custom: {
      type: Object as PropType<{
        options: Array<{ label: string; value: string }>;
        isFetching: boolean;
      } | null>,
      required: false,
      default: null,
    },
    searchQuery: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: {
    'update:modelValue': comboboxModelValueSchema.parse,
    'update:searchQuery': (_value: string) => true,
  },
  setup(props, context) {
    const { t } = useI18n();

    const brandSearch = ref<string>(props.searchQuery);

    watch(
      () => props.searchQuery,
      (newValue) => {
        brandSearch.value = newValue;
      }
    );

    const brandsSearchQuery = useBrandSearchQuery(debouncedRef(brandSearch, 300), {
      enabled: props.custom === null,
    });

    return () => {
      const options =
        props.custom !== null
          ? props.custom.options.filter((option) =>
              option.label.toLowerCase().includes(brandSearch.value.toLowerCase())
            )
          : brandsSearchQuery.data.value?.response?.map((brand) => ({
              label: `${brand?.display_name} (${brand?.user?.full_name})`,
              value: brand?.uuid ?? '',
            })) ?? [];

      return (
        <MiCombobox
          id={props.id}
          name={props.name}
          modelValue={props.modelValue}
          onUpdate:modelValue={(value) => {
            context.emit('update:modelValue', value as string);
          }}
          nullable
          placeholder={t('words--search_brands')}
          nothingFoundText={t('words--no_brands_found')}
          options={options}
          isLoading={props.custom?.isFetching ?? brandsSearchQuery.isFetching.value}
          searchQuery={brandSearch.value}
          onUpdate:searchQuery={(value) => {
            brandSearch.value = value;

            context.emit('update:searchQuery', value);
          }}
          inline={props.inline}
          multiple={props.multiple}
        />
      );
    };
  },
});
