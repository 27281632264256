import { type RouteRecordRaw } from 'vue-router';
import { isAuthenticated } from '~/router/middleware/is-authenticated';
import { notAuthenticated } from '~/router/middleware/not-authenticated.js';
import { whenHasActiveSubscription } from '~/router/middleware/when-has-active-subscription.business';
import { whenLoggedIn } from '~/router/middleware/when-logged-in';

export const publicRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'public',
    component: () => import('~/scopes/public/views/PublicView'),
    children: [
      {
        path: '',
        name: 'public.home',
        component: () => import('~/scopes/public/views/HomeView'),
        beforeEnter: [notAuthenticated()],
      },
      {
        path: '/login-token',
        name: 'login-token',
        beforeEnter: [
          (to, _from, next) => {
            if (!to.query.token) {
              return next({ name: 'public.home' });
            }

            return next();
          },
        ],
        component: () => import('~/scopes/public/views/LoginTokenView'),
      },
      /* {
        path: '/confirm-email',
        name: 'confirm-email',
        component: () => import('~/scopes/public/features/register/views/ConfirmEmailView.vue'),
        beforeEnter: [notAuthenticated],
      }, */
      {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('~/scopes/public/views/ForgotPasswordView'),
        beforeEnter: [notAuthenticated()],
      },
      {
        path: '/link',
        name: 'link',
        redirect: (to) => {
          if (
            to.query.action &&
            to.query.expires &&
            parseInt(to.query.expires.toString(), 10) > Math.round(Date.now() / 1000)
          ) {
            switch (to.query.action) {
              case 'confirm-email':
                return { name: 'confirm-email' };
              case 'instagram-login-failed':
                return { name: 'influencer.social-media.attach.failed' };
              case 'instagram-login-success':
                return { name: 'influencer.social-media.attach.success' };
            }
          }

          return { name: '404' };
        },
      },
      {
        path: '/signup',
        children: [
          {
            path: '',
            name: 'signup.get-started',
            beforeEnter: [notAuthenticated()],
            component: () =>
              import('~/scopes/public/views/GetStarted').then((module) => module.GetStarted),
          },
          {
            path: 'business',
            name: 'signup.business',
            redirect: () => {
              return {
                name: 'signup.business.step-1',
              };
            },
            component: () =>
              import('~/scopes/public/views/BusinessSignup').then(
                (module) => module.BusinessSignup
              ),
            children: [
              {
                path: 'step-1',
                name: 'signup.business.step-1',
                beforeEnter: [whenLoggedIn('signup.business.step-3')],
                component: () =>
                  import('~/scopes/public/views/BusinessSignup').then((module) => module.Step1),
              },
              {
                path: 'step-2',
                name: 'signup.business.step-2',
                beforeEnter: [whenLoggedIn('signup.business.step-3')],
                component: () =>
                  import('~/scopes/public/views/BusinessSignup').then((module) => module.Step2),
              },
              {
                path: 'step-3',
                name: 'signup.business.step-3',
                beforeEnter: [
                  isAuthenticated('signup.business.step-1'),
                  whenHasActiveSubscription('dashboard'),
                ],
                component: () =>
                  import('~/scopes/public/views/BusinessSignup').then((module) => module.Step3),
              },
            ],
          },
          {
            path: 'creator',
            name: 'signup.creator',
            beforeEnter: [whenLoggedIn('dashboard')],
            redirect: () => {
              return {
                name: 'signup.creator.step-1',
              };
            },
            component: () =>
              import('~/scopes/public/views/CreatorSignup').then((module) => module.CreatorSignup),
            children: [
              {
                path: 'step-1',
                name: 'signup.creator.step-1',
                component: () =>
                  import('~/scopes/public/views/CreatorSignup').then((module) => module.Step1),
              },
              {
                path: 'step-2',
                name: 'signup.creator.step-2',
                component: () =>
                  import('~/scopes/public/views/CreatorSignup').then((module) => module.Step2),
              },
            ],
          },
        ],
      },
    ],
  },
];
