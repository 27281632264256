import type { NavigationGuardWithThis } from 'vue-router';
import { pinia } from '~/plugins/pinia';
import { useAuthenticationStore } from '~/stores/authentication';

export const whenLoggedIn =
  (redirectTo = 'dashboard'): NavigationGuardWithThis<unknown> =>
  () => {
    const authenticationStore = useAuthenticationStore(pinia);

    if (authenticationStore.isLoggedIn) {
      return { name: redirectTo };
    }
  };
