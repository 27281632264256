import { match } from 'ts-pattern';
import type { NavigationGuardWithThis } from 'vue-router';
import { fetchStripeDetails } from '~/router/fetch-stripe-details.business';

export const businessRequireActiveSubscription = (): NavigationGuardWithThis<unknown> => {
  return async (to, _from, next) => {
    if (!to.path.toString().includes('business')) return next();

    const { hasActiveSubscription, hasStripeId } = await fetchStripeDetails();

    match({ hasActiveSubscription, hasStripeId })
      .with({ hasActiveSubscription: false, hasStripeId: false }, () => {
        next({ name: 'signup.business.step-3' });
      })
      .with({ hasActiveSubscription: false, hasStripeId: true }, () => {
        if (to.name === 'business.user-settings.billing') return next();

        next({ name: 'business.user-settings.billing' });
      })
      // we think this is impossible, but adding just in case
      .with({ hasActiveSubscription: true, hasStripeId: false }, () => {
        next();
      })
      .with({ hasActiveSubscription: true, hasStripeId: true }, () => {
        next();
      })
      .exhaustive();
  };
};
