import { createRouter, createWebHistory } from 'vue-router';
import { logout } from '~/composables/use-logout-mutation';
import { pinia } from '~/plugins/pinia';
import { businessRoutes } from '~/scopes/business/business.routes';
import { influencerRoutes } from '~/scopes/influencer/influencer.routes';
import { publicRoutes } from '~/scopes/public/public.routes';
import { getChatRoutes } from '~/shared/chat/chat.routes';
import { useAuthenticationStore } from '~/stores/authentication';
import { getUserTypeRedirectUrl } from '~/utils/get-user-type-redirect-url';

export const router = createRouter({
  history: createWebHistory(),
  // scroll: restore on back, top on forward, reset on new navigation
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return {
        el: to.hash,
      };
    }

    /**
     * Setting `meta.preventScroll = true` on a route will prevent the scroll behavior.
     * This is useful for modals, which should not scroll with the rest of the page.
     *
     * It doesn't matter if the `to` or `from` route is a modal, as long as one of them is.
     */
    if (to.meta.preventScroll || from.meta.preventScroll) {
      return false;
    }

    if (to.name === from.name) {
      return false;
    }

    return {
      top: 0,
    };
  },
  routes: [
    ...businessRoutes,
    ...influencerRoutes,
    ...publicRoutes,
    ...getChatRoutes(),
    {
      path: '/dashboard',
      name: 'dashboard',
      redirect: () => {
        const authenticationStore = useAuthenticationStore(pinia);

        if (authenticationStore.isInfluencer) {
          return { name: 'influencer.dashboard' };
        }

        if (authenticationStore.isBusiness) {
          return { name: 'business.discover' };
        }

        return { name: 'public.home' };
      },
    },
    {
      path: '/user-settings',
      name: 'user.settings',
      redirect: () => {
        const authenticationStore = useAuthenticationStore(pinia);

        if (authenticationStore.isInfluencer) {
          return { name: 'influencer.user-settings' };
        }

        if (authenticationStore.isBusiness) {
          return { name: 'business.user-settings' };
        }

        return { name: 'public.home' };
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('~/scopes/public/views/PageNotFoundView.vue'),
      meta: {
        hasHiddenMenu: true,
        isPublic: true,
      },
    },
  ],
});

router.beforeEach(async (_to, _from, next) => {
  const authenticationStore = useAuthenticationStore(pinia);

  // // Get parent app toolbar height
  // const dialogToolbar = window.top?.document?.querySelector(
  //   '.v-dialog__content .v-toolbar__content'
  // )

  // if (dialogToolbar) {
  //   console.log('dialogToolbar.clientHeight', dialogToolbar.clientHeight)
  //   document.body.style.setProperty('--app-header-height', `${dialogToolbar.clientHeight}px`)
  // }

  if (window.self !== window.top) return next();

  if (authenticationStore.isShadowLogin) {
    return next();
  }

  const redirectUrl = getUserTypeRedirectUrl(authenticationStore.userType);

  if (redirectUrl) {
    logout();
    authenticationStore.clearAuth();

    window.location.href = redirectUrl.toString();

    return;
  }

  next();
});
