import type { z } from 'zod';

export function emptySchemaKeys<
  Schema extends z.ZodObject<Record<string, z.ZodTypeAny>, z.UnknownKeysParam>,
>(schema: Schema) {
  return schema.keyof().options.reduce(
    (acc, key: keyof ReturnType<Schema['keyof']>['enum']) => {
      acc[key] = undefined;
      return acc;
    },
    {} as Record<keyof ReturnType<Schema['keyof']>['enum'], undefined>
  );
}

export function emptyObjectKeys<Obj extends Record<string, unknown>>(obj: Obj) {
  return Object.keys(obj).reduce(
    (acc, key: keyof Obj) => {
      acc[key] = undefined;
      return acc;
    },
    {} as Record<keyof Obj, undefined>
  );
}

export function emptyArrayKeys<Key extends string | number | symbol>(arr: Key[]) {
  return arr.reduce(
    (acc, key: Key) => {
      acc[key] = undefined;
      return acc;
    },
    {} as Record<Key, undefined>
  );
}
