import type { VariantProps } from 'tailwind-variants';
import { tv } from 'tailwind-variants';
import { match } from 'ts-pattern';

export const badge = tv({
  base: 'inline-flex shrink-0 cursor-default items-center justify-center whitespace-nowrap border text-center font-medium transition',
  variants: {
    size: {
      sm: '',
      base: '',
      lg: '',
    },
    variant: {
      white: 'border-gray-200 bg-white text-gray-700',
      gray: 'border-gray-100 bg-gray-50 text-gray-600',
      green: 'border-green-200 bg-green-50 text-green-700',
      red: 'border-red-200 bg-red-50 text-red-500',
      brightRed: 'border-red-600 bg-red-500 text-white',
      yellow: 'border-yellow-200 bg-yellow-50 text-yellow-700',
      darkGray: 'border-gray-800 bg-gray-900 text-white',
      blackTint: 'border-black-tint-2 bg-black-tint-2 text-white',
      black: 'border-black bg-black text-white',
      teal: 'border-teal-200 bg-teal-25 text-teal-700',
    },
    shape: {
      tag: 'rounded text-sm',
      circle: 'aspect-square rounded-full',
      pill: 'rounded-full text-sm',
    },
  },
  compoundVariants: [
    {
      size: 'sm',
      shape: 'tag',
      className: 'h-[1.375rem] px-1.5 leading-[1.375rem]',
    },
    {
      size: 'base',
      shape: 'tag',
      className: 'h-6 px-2 leading-6',
    },
    {
      size: 'lg',
      shape: 'tag',
      className: 'h-10 px-3 text-base leading-10',
    },
    {
      size: 'sm',
      shape: 'circle',
      className: 'w-4 text-[0.6rem] leading-[0.6rem]',
    },
    {
      size: 'base',
      shape: 'circle',
      className: 'w-5 text-[0.8rem] leading-[0.8rem]',
    },
    {
      size: 'lg',
      shape: 'circle',
      className: 'w-6 text-[1rem] leading-[1rem]',
    },
    {
      size: 'sm',
      shape: 'pill',
      className: 'h-[1.375rem] px-1.5 leading-[1.375rem]',
    },
    {
      size: 'base',
      shape: 'pill',
      className: 'h-6 px-2 leading-6',
    },
    {
      size: 'lg',
      shape: 'pill',
      className: 'h-10 px-3 text-base leading-10',
    },
  ],
  defaultVariants: {
    size: 'base',
    variant: 'gray',
    shape: 'tag',
  },
});

export type BadgeVariants = VariantProps<typeof badge>;

export const badgeButton = tv({
  extend: badge,
  variants: {
    variant: {
      white: 'hover:border-gray-400 hover:bg-gray-50',
      gray: 'hover:border-gray-300 hover:bg-gray-100',
      green: 'hover:border-green-400 hover:bg-green-100',
      red: 'hover:border-red-400 hover:bg-red-100',
      brightRed: 'hover:border-red-700 hover:bg-red-600',
      yellow: 'hover:border-yellow-400 hover:bg-yellow-100',
      darkGray: 'hover:border-gray-700 hover:bg-gray-800',
      blackTint: 'hover:border-black-tint-2 hover:bg-black-tint-2',
      teal: 'hover:border-teal-400 hover:bg-teal-100',
    },
    state: {
      selected: '',
    },
  },
  compoundVariants: [
    {
      variant: 'white',
      state: 'selected',
      className: 'border-gray-400 bg-white',
    },
    {
      variant: 'gray',
      state: 'selected',
      className: 'border-gray-300 bg-gray-100',
    },
    {
      variant: 'green',
      state: 'selected',
      className: 'border-green-400 bg-green-100',
    },
    {
      variant: 'red',
      state: 'selected',
      className: 'border-red-400 bg-red-100',
    },
    {
      variant: 'brightRed',
      state: 'selected',
      className: 'border-red-700 bg-red-600',
    },
    {
      variant: 'yellow',
      state: 'selected',
      className: 'border-yellow-400 bg-yellow-100',
    },
    {
      variant: 'darkGray',
      state: 'selected',
      className: 'border-gray-700 bg-gray-800',
    },
    {
      variant: 'blackTint',
      state: 'selected',
      className: 'border-black-tint-2 bg-black-tint-2',
    },
    {
      variant: 'teal',
      state: 'selected',
      className: 'border-teal-400 bg-teal-100',
    },
  ],
});

export type BadgeButtonVariants = VariantProps<typeof badgeButton>;

export function handleBadgeButtonState(options: {
  [K in NonNullable<VariantProps<typeof badgeButton>['state']>]?: boolean;
}) {
  return match(options)
    .with({ selected: true }, () => 'selected' as const)
    .otherwise(() => undefined);
}
