import type { MaybeRef } from 'vue';
import { ref, watchEffect } from 'vue';

type UseDialogOptions = {
  isOpen?: MaybeRef<boolean>;
  onClosing?: () => void;
  onClose?: () => void;
  animationOptions?: MaybeRef<KeyframeAnimationOptions>;
  dialogKeyframes?: MaybeRef<Keyframe[]>;
  contentKeyframes?: MaybeRef<Keyframe[]>;
};

export function useDialog(options: UseDialogOptions) {
  const dialogRef = ref<HTMLDialogElement | null>(null);
  const contentRef = ref<HTMLDivElement | null>(null);

  const mounted = ref(false);
  const isOpen = ref(options.isOpen);
  const animationOptions = ref(
    options.animationOptions ?? { duration: 200, easing: 'ease-in-out' }
  );
  const dialogKeyframes = ref(options.dialogKeyframes ?? [{ opacity: 0 }, { opacity: 1 }]);
  const contentKeyframes = ref(
    options.contentKeyframes ?? [{ transform: 'translateY(-50px)' }, { transform: 'translateY(0)' }]
  );

  function handleClose(event?: Event) {
    event?.preventDefault();

    options.onClosing?.();

    const dialogKeyframesReverse = dialogRef.value?.animate(dialogKeyframes.value, {
      ...animationOptions.value,
      direction: 'reverse',
    });

    dialogKeyframesReverse?.addEventListener('finish', () => {
      dialogRef.value?.close();

      options.onClose?.();
    });
  }

  watchEffect((onCleanup) => {
    let done = false as boolean;

    /**
     * TODO: Animations are rerunning when a Combobox fields is being selected,
     * but the dialog is already open. This is because the isOpen value is
     * changing, but the dialog is already open. We should only animate
     * when the dialog is opening or closing.
     *
     * Weirdly, it only happens the options of the Combobox is being opened.
     *
     * Check UpfrontPaymentDialog.tsx for an example.
     */
    if (isOpen.value) {
      dialogRef.value?.addEventListener('close', (event) => {
        if (!done) handleClose(event);
      });

      dialogRef.value?.animate(dialogKeyframes.value, animationOptions.value);
      contentRef.value?.animate(contentKeyframes.value, animationOptions.value);

      /**
       * Safari bug:
       * "InvalidStateError: The object is in an invalid state."
       */
      try {
        dialogRef.value?.showModal();
      } catch (error) {
        console.log(error);
      }
    } else {
      if (mounted.value && dialogRef.value?.open && !done) {
        handleClose(new Event('close'));
      }

      dialogRef.value?.removeEventListener('close', (event) => {
        if (!done) handleClose(event);
      });
    }

    onCleanup(() => {
      dialogRef.value?.removeEventListener('close', (event) => {
        if (!done) handleClose(event);
      });
    });

    mounted.value = true;
    done = true;
  });

  return [
    {
      dialog: dialogRef,
      content: contentRef,
    },
    {
      handleClose,
    },
  ] as const;
}
