import type { VariantProps } from 'tailwind-variants';
import { tv } from 'tailwind-variants';
import { P, match } from 'ts-pattern';
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import { z } from 'zod';

export const alertMessageEnum = z.enum(['success', 'error', 'warning', 'info', 'neutral']);

const alertMessage = tv({
  base: 'rounded-lg border border-gray-100 bg-gray-200 p-4 text-gray-900',
  variants: {
    type: {
      [alertMessageEnum.enum.success]: 'border-green-100 bg-green-50 text-green-900',
      [alertMessageEnum.enum.error]: 'border-red-100 bg-red-50 text-red-900',
      [alertMessageEnum.enum.warning]: 'border-yellow-100 bg-yellow-50 text-yellow-900',
      [alertMessageEnum.enum.info]: 'border-blue-100 bg-blue-50 text-blue-900',
      [alertMessageEnum.enum.neutral]: 'border-gray-200 bg-gray-100 text-gray-900',
    },
  },

  defaultVariants: {
    type: 'neutral',
  },
});

export const AlertMessage = defineComponent({
  name: 'AlertMessage',
  props: {
    type: {
      type: String as PropType<VariantProps<typeof alertMessage>['type']>,
      required: true,
    },
  },
  setup(props, { slots }) {
    const role = computed(() => {
      return match(props.type)
        .with(P.union('error', 'warning'), () => 'alert')
        .otherwise(() => 'status');
    });

    return () => (
      <div class={alertMessage({ type: props.type })} role={role.value}>
        {slots.default?.()}
      </div>
    );
  },
});
