import { zodHasArray, type ZodObjectSchema } from '@makeinfluence/ui/src/utils/zod-has-array';
import { isEqual } from 'lodash-es';
import type { MaybeRef } from 'vue';
import { computed, nextTick, reactive, unref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { z } from 'zod';

function zodDefaultValue(schema?: z.ZodType<unknown, z.ZodAny>) {
  if (schema instanceof z.ZodDefault) {
    return schema._def.defaultValue();
  }

  if (schema && '_def' in schema && typeof schema._def === 'object' && 'innerType' in schema._def) {
    return zodDefaultValue(schema._def.innerType as z.ZodType<unknown, z.ZodAny>);
  }
}

function zodCatchValue(schema?: z.ZodType<unknown, z.ZodAny, unknown>) {
  if (schema instanceof z.ZodCatch) {
    return schema._def.catchValue({
      error: new z.ZodError([]),
      input: undefined,
    });
  }

  if (schema && '_def' in schema && typeof schema._def === 'object' && 'innerType' in schema._def) {
    return zodCatchValue(schema._def.innerType as z.ZodType<unknown, z.ZodAny>);
  }
}

type UseZodParamsOptions<Schema extends ZodObjectSchema> = {
  schema: Schema;
  enabled?: MaybeRef<boolean>;
  debounceMs?: number;
  global?: boolean;
};

const stack = new Map<string, string | string[] | null | undefined>();

function getCurrentStackUrl() {
  const url = new URL(window.location.href);

  for (const entry of stack.entries()) {
    if (Array.isArray(entry[1])) {
      url.searchParams.delete(entry[0]);

      for (const value of entry[1]) {
        if (!value) continue;

        url.searchParams.append(entry[0], value.toString());
      }
    } else {
      url.searchParams.set(entry[0], entry[1] ?? '');
    }
  }

  return url;
}

// Outside your export function, define a map to hold debounced functions
export function useZodParams<Schema extends ZodObjectSchema>({
  schema,
  enabled = true,
  global = false,
}: UseZodParamsOptions<Schema>) {
  const router = useRouter();
  const route = useRoute();
  const mountedRouteName = route.name;

  const hasMatchedRoute = computed(() => {
    if (global) return true;

    return route.matched.some((m) => m.name === mountedRouteName);
  });

  const proxy = new Proxy(reactive({} as z.infer<Schema>), {
    get(target, key) {
      if (!unref(enabled)) return null;
      if (!unref(hasMatchedRoute)) return null;

      /**
       * Vue Router returns a single value if there's only one array item.
       * For consistent parameter handling, convert it to an array if the
       * schema contains an array.
       */
      const updatedTarget = Object.fromEntries(
        Object.entries(target).map(([key, value]) => {
          const schemaPath = schema.shape[key];

          return [
            key,
            schemaPath && zodHasArray(schemaPath) && !Array.isArray(value) ? [value] : value,
          ];
        })
      );

      return Reflect.get(schema.parse(updatedTarget), key);
    },
    set(target, key, value) {
      if (!unref(enabled) || !unref(hasMatchedRoute)) return true;

      const schemaPath = schema.shape[key.toString()];

      // Prepare the new value according to the schema
      const newValue =
        schemaPath && zodHasArray(schemaPath) && !Array.isArray(value) ? [value] : value;

      stack.set(key.toString(), newValue);

      nextTick(() => {
        const url = getCurrentStackUrl();

        if (window.location.href === url.href) return;

        router.replace(url.pathname + url.search);

        nextTick(() => {
          stack.clear();
        });
      });

      /**
       * Use Reflect.set to update the value of the key in the target object.
       * This is necessary to ensure that the changes are propagated to all
       * references of the object.
       */
      return Reflect.set(target, key, newValue);
    },
  });

  // Watch for changes in the route and update the proxy accordingly
  watch(route, (newRoute) => {
    if (!unref(enabled)) return;
    if (!unref(hasMatchedRoute)) return;

    const newValues = Object.keys(newRoute.query).reduce(
      (acc, key) => {
        const schemaPath = schema.shape[key];
        const value = newRoute.query[key];

        if (!value && schemaPath instanceof z.ZodOptional) {
          return acc;
        }

        const catchValue = zodCatchValue(
          schemaPath as unknown as z.ZodType<unknown, z.ZodAny, unknown>
        );
        const defaultValue = zodDefaultValue(schemaPath as unknown as z.ZodType<unknown, z.ZodAny>);
        acc[key] = value || defaultValue || catchValue;
        return acc;
      },
      {} as Record<string, unknown>
    );

    // Clear keys not present in newRoute
    const existingKeys = Object.keys(proxy);
    const newKeys = Object.keys(newRoute.query);
    const keysToClear = existingKeys.filter((key) => !newKeys.includes(key));

    for (const key of keysToClear) {
      newValues[key] = undefined;
    }

    Object.assign(proxy, newValues);
  });

  // Initial setting of keys when the function is called
  const initialValues = Object.keys(schema.shape).reduce(
    (acc, key) => {
      const schemaPath = schema.shape[key];
      const value = route.query[key];

      if (!value && schemaPath instanceof z.ZodOptional) {
        return acc;
      }

      const catchValue = zodCatchValue(
        schemaPath as unknown as z.ZodType<unknown, z.ZodAny, unknown>
      );
      const defaultValue = zodDefaultValue(schemaPath as unknown as z.ZodType<unknown, z.ZodAny>);
      acc[key] = value || defaultValue || catchValue;
      return acc;
    },
    {} as Record<string, unknown>
  );

  Object.assign(proxy, initialValues);

  return proxy;
}

export function zodAllDefaultValuesEqual<Schema extends ZodObjectSchema>(
  schema: Schema,
  values: Record<string, unknown>
) {
  return Object.keys(schema.shape).every((key) => {
    const schemaPath = schema.shape[key];
    const value = values[key];
    const defaultValue = zodDefaultValue(schemaPath as unknown as z.ZodType<unknown, z.ZodAny>);

    return isEqual(value, defaultValue);
  });
}
