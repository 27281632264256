import type themes from '~/themes';
import { defineStore } from 'pinia';

type Colors = keyof (typeof themes)[number]['light'];

type Snack = {
  message: string;
  type?: Colors;
};

export const useFeedbackStore = defineStore({
  id: 'feedback',
  state: () => ({
    snackbar: {
      isVisible: false,
      message: '',
      color: 'primary',
    },
  }),
  actions: {
    triggerSnackbar({ type = 'primary', message }: Snack) {
      this.snackbar.message = message;
      this.snackbar.isVisible = true;
      this.snackbar.color = type;
    },
  },
});
