import type { NavigationGuardWithThis } from 'vue-router';
import { pinia } from '~/plugins/pinia';
import { useAuthenticationStore } from '~/stores/authentication';

export const isAuthenticated = (redirectTo = 'public.home'): NavigationGuardWithThis<unknown> => {
  return () => {
    const authenticationStore = useAuthenticationStore(pinia);

    if (
      !authenticationStore.auth.expiresAt ||
      new Date(authenticationStore.auth.expiresAt).getTime() < Date.now()
    ) {
      return { name: redirectTo };
    }
  };
};
