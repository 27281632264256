import { tv } from 'tailwind-variants';

export const dialogBase = tv({
  base: 'backdrop:fixed backdrop:inset-0 backdrop:bg-black/30',
});

export const dialogContent = tv({
  base: 'relative max-h-full w-full overflow-y-auto overflow-x-hidden rounded-lg border border-gray-200 bg-white shadow-lg',
});

export const dialogOutside = tv({
  base: 'fixed inset-0 flex min-h-full items-center justify-center p-4',
});

export const circleButton = tv({
  base: 'inline-flex size-8 shrink-0 items-center justify-center rounded-full border border-gray-200 outline-2 hover:bg-gray-200 focus:outline',
});
