import { createEnv } from '@t3-oss/env-core';
import { z } from 'zod';
import { i18n } from '~/plugins/i18n';

if (typeof process === 'undefined') {
  globalThis.process = {
    env: {},
  } as unknown as NodeJS.Process;
}

const requiredString = z.string().min(1, {
  message: i18n.global.t('words--required'),
});

export const env = createEnv({
  clientPrefix: 'VITE_',
  client: {
    VITE_APP_REDIRECT_URL_BUSINESS: z.string().optional(),
    VITE_APP_REDIRECT_URL_INFLUENCER: z.string().optional(),
    VITE_APP_REDIRECT_URL_MODERATOR: z.string().optional(),
    VITE_INTERCOM_APP_ID: z.string().optional(),
    VITE_SENDBIRD_APP_ID: requiredString,
    VITE_STRIPE_PRICING_TABLE_ID_BUSINESS_SIGNUP: requiredString,
    VITE_STRIPE_PUBLISHABLE_KEY: requiredString,
    VITE_USERFLOW_TOKEN: z.string().optional(),
    VITE_V1_GRAPHQL_ENDPOINT: requiredString.url(),
    VITE_V1_ORIGIN_DOMAIN: z.string().optional(),
    VITE_POSTHOG_API_KEY: z.string().optional(),
    VITE_POSTHOG_API_HOST: z.string().optional(),
    VITE_POSTHOG_CHAT_KEYWORDS: z.string().optional(),
    VITE_BEAMER_ID: z.string().optional(),
    VITE_SENTRY_APP_ID: z.string().optional(),
    VITE_SENTRY_ORG_ID: z.string().optional(),
    VITE_SENTRY_PROJECT_ID: z.string().optional(),
    VITE_SENTRY_SAMPLE_RATE: z.coerce.number().optional(),
    VITE_HUBSPOT_APP_ID: z.string().optional(),
    VITE_GTM_ID: z.string().optional(),
  },
  runtimeEnv: {
    VITE_INTERCOM_APP_ID: import.meta.env.VITE_INTERCOM_APP_ID || process.env.VITE_INTERCOM_APP_ID,
    VITE_SENDBIRD_APP_ID: import.meta.env.VITE_SENDBIRD_APP_ID || process.env.VITE_SENDBIRD_APP_ID,
    VITE_STRIPE_PRICING_TABLE_ID_BUSINESS_SIGNUP:
      import.meta.env.VITE_STRIPE_PRICING_TABLE_ID_BUSINESS_SIGNUP ||
      process.env.VITE_STRIPE_PRICING_TABLE_ID_BUSINESS_SIGNUP,
    VITE_STRIPE_PUBLISHABLE_KEY:
      import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || process.env.VITE_STRIPE_PUBLISHABLE_KEY,
    VITE_USERFLOW_TOKEN: import.meta.env.VITE_USERFLOW_TOKEN || process.env.VITE_USERFLOW_TOKEN,
    VITE_V1_GRAPHQL_ENDPOINT:
      import.meta.env.VITE_V1_GRAPHQL_ENDPOINT || process.env.VITE_V1_GRAPHQL_ENDPOINT,
    VITE_APP_REDIRECT_URL_BUSINESS:
      import.meta.env.VITE_APP_REDIRECT_URL_BUSINESS || process.env.VITE_APP_REDIRECT_URL_BUSINESS,
    VITE_APP_REDIRECT_URL_INFLUENCER:
      import.meta.env.VITE_APP_REDIRECT_URL_INFLUENCER ||
      process.env.VITE_APP_REDIRECT_URL_INFLUENCER,
    VITE_APP_REDIRECT_URL_MODERATOR:
      import.meta.env.VITE_APP_REDIRECT_URL_MODERATOR ||
      process.env.VITE_APP_REDIRECT_URL_MODERATOR,
    VITE_V1_ORIGIN_DOMAIN:
      import.meta.env.VITE_V1_ORIGIN_DOMAIN || process.env.VITE_V1_ORIGIN_DOMAIN,
    VITE_POSTHOG_API_KEY: import.meta.env.VITE_POSTHOG_API_KEY || process.env.VITE_POSTHOG_API_KEY,
    VITE_POSTHOG_API_HOST:
      import.meta.env.VITE_POSTHOG_API_HOST || process.env.VITE_POSTHOG_API_HOST,
    VITE_POSTHOG_CHAT_KEYWORDS:
      import.meta.env.VITE_POSTHOG_CHAT_KEYWORDS || process.env.VITE_POSTHOG_CHAT_KEYWORDS,
    VITE_BEAMER_ID: import.meta.env.VITE_BEAMER_ID || process.env.VITE_BEAMER_ID,
    VITE_SENTRY_APP_ID: import.meta.env.VITE_SENTRY_APP_ID || process.env.VITE_SENTRY_APP_ID,
    VITE_SENTRY_ORG_ID: import.meta.env.VITE_SENTRY_ORG_ID || process.env.VITE_SENTRY_ORG_ID,
    VITE_SENTRY_PROJECT_ID:
      import.meta.env.VITE_SENTRY_PROJECT_ID || process.env.VITE_SENTRY_PROJECT_ID,
    VITE_SENTRY_SAMPLE_RATE:
      import.meta.env.VITE_SENTRY_SAMPLE_RATE || process.env.VITE_SENTRY_SAMPLE_RATE,
    VITE_HUBSPOT_APP_ID: import.meta.env.VITE_HUBSPOT_APP_ID || process.env.VITE_HUBSPOT_APP_ID,
    VITE_GTM_ID: import.meta.env.VITE_GTM_ID || process.env.VITE_GTM_ID,
  },
  emptyStringAsUndefined: true,
  isServer: false,
});
