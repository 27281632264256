import { initGraphQLTada } from 'gql.tada';
import type { introspection } from './graphql-env.d.ts';
import type { V1Schema } from '@makeinfluence/graphql-schema/types';

export const graphql = initGraphQLTada<{
  introspection: introspection;
  scalars: {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Date: string;
    DateTime: string;
    JSON: unknown;
    Upload: unknown;
    Gender: V1Schema.Gender;
  };
}>();

export type { FragmentOf, ResultOf, VariablesOf } from 'gql.tada';
export { readFragment } from 'gql.tada';
