import { defineComponent } from 'vue';
import { VSnackbar } from 'vuetify/lib/components/index.mjs';
import { useFeedbackStore } from '~/stores/feedback';

export const SnackBar = defineComponent({
  name: 'SnackBar',
  props: {
    timeout: {
      type: Number,
      required: false,
      default: 4000,
    },
  },
  setup(props) {
    const feedbackStore = useFeedbackStore();

    return () => (
      <VSnackbar
        modelValue={feedbackStore.snackbar.isVisible}
        onUpdate:modelValue={(value) => {
          feedbackStore.snackbar.isVisible = value;
        }}
        color={feedbackStore.snackbar.color}
        timeout={props.timeout}
      >
        {feedbackStore.snackbar.message}
      </VSnackbar>
    );
  },
});
