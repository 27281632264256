import { createI18n } from 'vue-i18n';

import en from '~/lang/en.json';

const numberFormats = {
  en: {
    currency: {
      style: 'currency',
      currency: 'DKK',
      maximumFractionDigits: 0,
    },
  },
  da: {
    currency: {
      style: 'currency',
      currency: 'DKK',
      currencyDisplay: 'code',
      maximumFractionDigits: 0,
    },
  },
};

export const i18n = createI18n({
  numberFormats,
  locale: 'en',
  messages: { en },
});
