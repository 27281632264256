import type { RouteRecordRaw } from 'vue-router';
import { businessRequireActiveSubscription } from '~/router/middleware/business-require-active-subscription';
import { isAuthenticated } from '~/router/middleware/is-authenticated';
import { isBusiness } from '~/router/middleware/is-business';
import { getChatRoutes } from '~/shared/chat/chat.routes';
import type { GetChildrenRouteNames } from '~/types';

export const businessRoutes = [
  {
    path: '/business',
    name: 'business' as const,
    component: () => import('~/scopes/business/BusinessView'),
    beforeEnter: [isAuthenticated(), isBusiness(), businessRequireActiveSubscription()],
    redirect: {
      name: 'business.discover' as const,
    },
    children: [
      ...getChatRoutes('business'),
      {
        path: 'promotion-codes',
        name: 'business.promotion-codes' as const,
        component: () =>
          import('~/scopes/business/features/promotion-codes/views/BusinessPromotionCodesView'),
      },
      {
        path: 'settings',
        name: 'business.settings' as const,
        component: () => import('~/shared/NestedRouterView.vue'),
      },
      {
        path: 'reports',
        name: 'business.reports' as const,
        component: () =>
          import('~/scopes/business/features/reports/views/BusinessReportsParentView'),
        redirect: () => {
          return {
            name: 'business.reports.dashboard' as const,
          };
        },
        children: [
          {
            path: 'dashboard',
            name: 'business.reports.dashboard' as const,
            component: () =>
              import('~/scopes/business/features/reports/views/BusinessReportsDashboardView'),
          },
          {
            path: 'influencer',
            name: 'business.reports.influencer' as const,
            component: () =>
              import('~/scopes/business/features/reports/views/BusinessReportsInfluencerView'),
          },
          {
            path: 'campaign',
            name: 'business.reports.campaign' as const,
            component: () =>
              import('~/scopes/business/features/reports/views/BusinessReportsCampaignView'),
          },
          {
            path: 'daily',
            name: 'business.reports.daily' as const,
            component: () =>
              import('~/scopes/business/features/reports/views/BusinessReportsDailyView'),
          },
          {
            path: 'summary',
            name: 'business.reports.summary' as const,
            component: () =>
              import('~/scopes/business/features/reports/views/BusinessReportsSummaryView'),
          },
          {
            path: 'conversions',
            name: 'business.reports.conversions' as const,
            component: () =>
              import('~/scopes/business/features/reports/views/BusinessReportsConversionsView'),
          },
        ],
      },
      {
        path: 'discover',
        name: 'business.discover' as const,
        component: () =>
          import('~/scopes/business/features/discover/views/DiscoverView').then(
            (module) => module.DiscoverView
          ),
      },
      {
        path: 'campaigns',
        children: [
          {
            path: '',
            name: 'business.campaigns' as const,
            component: () =>
              import('~/scopes/business/features/campaigns/views/CampaignsView').then(
                (module) => module.BusinessCampaignsView
              ),
          },
          {
            path: ':id',
            component: () =>
              import('~/scopes/business/features/campaign/views/CampaignView').then(
                (module) => module.CampaignView
              ),
            children: [
              {
                path: '',
                name: 'business.campaign' as const,
                component: () =>
                  import('~/scopes/business/features/campaign/views/DashboardView.vue'),
              },
              {
                path: 'collaborations',
                name: 'business.campaign.collaborations' as const,
                component: () =>
                  import('~/scopes/business/features/campaign/views/CollaborationsView'),
              },
              {
                path: 'requests',
                name: 'business.campaign.requests' as const,
                component: () => import('~/scopes/business/features/campaign/views/RequestsView'),
              },
              {
                path: 'conversions',
                name: 'business.campaign.conversions' as const,
                component: () =>
                  import('~/scopes/business/features/campaign/views/ConversionsView.vue'),
              },
              {
                path: 'content',
                name: 'business.campaign.content' as const,
                component: () =>
                  import('~/scopes/business/features/campaign/views/ContentView.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'edit-campaign/:campaignId',
        name: 'business.edit-campaign' as const,
        component: () =>
          import('~/scopes/business/features/edit-campaign/views/EditCampaignView').then(
            (module) => module.EditCampaignView
          ),
        redirect: () => {
          return {
            name: 'business.edit-campaign.landing-page' as const,
          };
        },
        children: [
          {
            path: 'landing-page',
            name: 'business.edit-campaign.landing-page' as const,
            component: () =>
              import('~/scopes/business/features/edit-campaign/views/EditCampaignView').then(
                (module) => module.EditCampaignLandingPage
              ),
          },
          {
            path: 'banner',
            name: 'business.edit-campaign.banner' as const,
            component: () =>
              import('~/scopes/business/features/edit-campaign/views/EditCampaignView').then(
                (module) => module.EditBannerView
              ),
          },
          {
            path: 'category',
            name: 'business.edit-campaign.category' as const,
            component: () =>
              import('~/scopes/business/features/edit-campaign/views/EditCampaignView').then(
                (module) => module.EditCampaignCategory
              ),
          },
          {
            path: 'campaign-details',
            name: 'business.edit-campaign.campaign-details' as const,
            component: () =>
              import('~/scopes/business/features/edit-campaign/views/EditCampaignView').then(
                (module) => module.EditCampaignDetails
              ),
          },
          {
            path: 'compensation',
            name: 'business.edit-campaign.compensation' as const,
            component: () =>
              import('~/scopes/business/features/edit-campaign/views/EditCampaignView').then(
                (module) => module.EditCampaignCompensation
              ),
          },
          {
            path: 'description',
            name: 'business.edit-campaign.description' as const,
            component: () =>
              import('~/scopes/business/features/edit-campaign/views/EditCampaignView').then(
                (module) => module.EditCampaignDescription
              ),
          },
        ],
      },
      // {
      //   path: 'campaign/:id/create',
      //   name: 'business.edit-campaign' as const,
      //   component: () =>
      //     import('~/scopes/business/features/campaigns/views/CampaignEditLayout.vue'),
      // },
      {
        path: 'influencer/:influencerId',
        name: 'business.influencer' as const,
        component: () =>
          import('~/scopes/business/features/influencer/views/BusinessInfluencerView').then(
            (module) => module.BusinessInfluencerView
          ),
      },
      {
        path: 'user-settings',
        component: () =>
          import('~/scopes/business/features/user-settings/views/BusinessUserSettingsView').then(
            (module) => module.BusinessUserSettingsView
          ),
        children: [
          {
            path: '',
            name: 'business.user-settings' as const,
            component: () =>
              import('~/shared/user/views/UserSettingsProfileView').then(
                (module) => module.UserSettingsProfileView
              ),
          },
          {
            path: 'password',
            name: 'business.user-settings.password' as const,
            component: () =>
              import('~/shared/user/views/UserSettingsPasswordView').then(
                (module) => module.UserSettingsPasswordView
              ),
          },
          {
            path: 'notifications',
            name: 'business.user-settings.notifications' as const,
            component: () =>
              import('~/scopes/business/features/user-settings/views/NotificationsView').then(
                (module) => module.BusinessUserSettingsNotifications
              ),
          },
          {
            path: 'company',
            name: 'business.user-settings.company' as const,
            component: () =>
              import('~/scopes/business/features/user-settings/views/CompanyView').then(
                (module) => module.BusinessUserSettingsCompanyView
              ),
          },
          {
            path: 'billing',
            name: 'business.user-settings.billing' as const,
            component: () =>
              import('~/scopes/business/features/user-settings/views/BillingView').then(
                (module) => module.BusinessUserSettingsBillingView
              ),
          },
        ],
      },
    ],
  },
] satisfies RouteRecordRaw[];

export type BusinessRouteNames = GetChildrenRouteNames<typeof businessRoutes>;

export const businessMenuRoutes = {
  desktop: [
    'business.discover',
    'business.campaigns',
    'business.reports',
    'business.promotion-codes',
  ],
  overlay: ['business.user-settings'],
} satisfies Record<'desktop' | 'overlay', BusinessRouteNames[]>;
