import type { V1Schema } from '@makeinfluence/graphql-schema/types';
import { useMutation } from '@tanstack/vue-query';
import { graphql } from '~/graphql';
import { client } from '~/plugins/client';

export function useCreateChatMutation() {
  return useMutation({
    mutationFn: async (variables: V1Schema.SendbirdChannelInput) => {
      const response = await client.request({
        document: graphql(`
          mutation CreateSendbirdChannel($user_uuid: String!) {
            createSendbirdChannel(input: { user_uuid: $user_uuid }) {
              channel_url
            }
          }
        `),
        variables,
      });

      return response.createSendbirdChannel;
    },
  });
}
