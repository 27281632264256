import { env } from '~/env';
import { pinia } from '~/plugins/pinia';
import { useAuthenticationStore } from '~/stores/authentication';

const authenticationStore = useAuthenticationStore(pinia);

window.addEventListener(
  'message',
  (event) => {
    const domains = env.VITE_V1_ORIGIN_DOMAIN ? env.VITE_V1_ORIGIN_DOMAIN.split(',') : [];
    const hasValidOrigin =
      domains.length &&
      domains.some((domain: string) => {
        return event.origin.includes(domain);
      });

    if (!hasValidOrigin) return;

    const { payload, type } = typeof event.data === 'object' ? event.data : JSON.parse(event.data);

    if (type !== 'MI_CREDENTIALS') return;

    if (payload) {
      authenticationStore.setAuth({
        accessToken: payload.accessToken,
        shadowLogin: payload.shadowLogin || false,
        expiresAt: payload.tokenExpiresAt,
        refreshToken: payload.refreshToken,
        user: {
          uuid: payload.userId,
          createdAt: payload.createdAt,
          email: payload.email,
          fullName: payload.fullName,
          businessUuid: payload.businessUuid,
          influencerUuid: payload.influencerUuid,
          userType: payload.userType,
        },
      });
    }
  },
  false
);
