import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import type { ClassValue, VariantProps } from 'tailwind-variants';
import { tv } from 'tailwind-variants';

export const shimmerContainer = tv({
  base: 'relative inline-block max-w-full overflow-hidden',
});

export const shimmer = tv({
  base: 'before:absolute before:inset-x-0 before:-top-1 before:bottom-0 before:-translate-x-full before:-skew-x-12 before:animate-[shimmer_2s_infinite] before:border-t before:bg-gradient-to-r before:from-transparent before:to-transparent',
  variants: {
    variant: {
      light: 'before:border-white/5 before:via-white/5',
      dark: 'before:border-black/5 before:via-black/5',
    },
  },
});

export const LoadingShimmer = defineComponent({
  name: 'LoadingShimmer',
  props: {
    variant: {
      type: String as PropType<VariantProps<typeof shimmer>['variant']>,
      required: true,
    },
  },
  setup(props, context) {
    return () => (
      <span
        {...context.attrs}
        class={shimmerContainer({
          className: context.attrs.class as ClassValue,
        })}
      >
        <div
          class={shimmer({
            variant: props.variant,
          })}
        />

        {context.slots.default?.()}
      </span>
    );
  },
});
