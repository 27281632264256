import { z } from 'zod';

export const campaignStatusEnum = z.enum(['active', 'not_started', 'ended']);

export enum CampaignInfluencerStatus {
  STATUS_INVITATION_PENDING = 1,
  STATUS_APPLICATION_PENDING = 2,
  STATUS_INVITATION_ACCEPTED = 3,
  STATUS_APPLICATION_ACCEPTED = 4,
  STATUS_INVITATION_DECLINED = 5,
  STATUS_APPLICATION_DECLINED = 6,
  STATUS_INVITATION_RETRACTED = 7,
  STATUS_APPLICATION_RETRACTED = 8,
  STATUS_INVITATION_DECLINED_AFTER_ACCEPTED = 9,
  STATUS_APPLICATION_DECLINED_AFTER_ACCEPTED = 10,
  STATUS_INVITATION_RETRACTED_AFTER_ACCEPTED = 11,
  STATUS_APPLICATION_RETRACTED_AFTER_ACCEPTED = 12,
}

export const campaignInfluencerStatus = z.nativeEnum(CampaignInfluencerStatus);

export const influencersOrderByEnum = z.enum([
  'recommended',
  'newest',
  'oldest',
  'followers',
  'impressions',
  // 'alphabetical',
  // 'first_name',
  // 'email',
  // 'reach',
  // 'channels',
  // 'created_at',
]);
