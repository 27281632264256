import { env } from '~/env';

/**
 * Our GraphQL API returns a user type as a string. This function
 * takes that string and returns a type-safe user type.
 *
 * @example
 * ```ts
 * const userType = getUserType('business')
 * // userType: 'business'
 * const userType = getUserType('influencer')
 * // userType: 'influencer'
 * const userType = getUserType('App\\Models\\Business')
 * // userType: 'business'
 * const userType = getUserType('App\\Models\\Influencer')
 * // userType: 'influencer'
 * ```
 *
 * @param userType - The user type input
 * @returns A type-safe user type
 */
export function getUserType(userType?: string | null) {
  const userTypes = ['business', 'influencer', 'moderator'] as const;

  return userTypes.find((type) => {
    return userType?.toLowerCase().includes(type);
  });
}

const userTypeRedirectDomains = {
  business: env.VITE_APP_REDIRECT_URL_BUSINESS,
  influencer: env.VITE_APP_REDIRECT_URL_INFLUENCER,
  moderator: env.VITE_APP_REDIRECT_URL_MODERATOR,
} as const;

export function getUserTypeRedirectUrl(userType?: keyof typeof userTypeRedirectDomains | null) {
  if (!userType) return null;

  const redirectDomain = userTypeRedirectDomains[userType];

  if (!redirectDomain) {
    return null;
  }

  const protocol = redirectDomain.includes('localhost') ? 'http:' : 'https:';
  const targetUrl = new URL(`${protocol}//${redirectDomain}`);

  if (targetUrl.hostname !== window.location.hostname || targetUrl.port !== window.location.port) {
    return targetUrl;
  }

  return null;
}
