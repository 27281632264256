/**
 * Based on huanguolin's comment on GitHub:
 * @see https://github.com/TkDodo/blog-comments/discussions/64#discussioncomment-2575612
 */

type Cast<X, Y> = X extends Y ? X : Y;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Fn = (...args: any[]) => any[] | readonly any[];

type KeyConfig = {
  [k: string]: KeyConfig | Fn | unknown;
};

type CacheKeyHelperType<Prefixes extends readonly string[], T extends KeyConfig> = {
  [K in keyof T]: T[K] extends Fn
    ? {
        toKey: () => [...Prefixes, K];
        toKeyWithArgs: (...args: Parameters<T[K]>) => [...Prefixes, K, ...ReturnType<T[K]>];
        toArgsFromKey: (keys: [...Prefixes, K, ...ReturnType<T[K]>]) => Parameters<T[K]>[0];
      }
    : T[K] extends KeyConfig
      ? { toKey: () => [...Prefixes, K] } & CacheKeyHelperType<
          Cast<[...Prefixes, K], string[]>,
          Cast<T[K], KeyConfig>
        >
      : { toKey: () => [...Prefixes, K] };
};

type CacheKeysOptions<Prefixes extends string[], Config extends KeyConfig> = {
  prefix: Prefixes;
  keyConfig: Config;
};

export function cacheKeys<const Prefixes extends string[], const Config extends KeyConfig>(
  options: CacheKeysOptions<Prefixes, Config>
): CacheKeyHelperType<Prefixes, Config> {
  const keyFn = (name: string) => options.prefix.concat([name]);
  const cacheObj = {} as KeyConfig;

  for (const k of Object.keys(options.keyConfig)) {
    const v = options.keyConfig[k];
    if (typeof v === 'function') {
      cacheObj[k] = {
        toKeyWithArgs: (...args: unknown[]) => [...keyFn(k), ...v(...args)],
        toKey: () => keyFn(k),
        toArgsFromKey: (keys: string[]) => {
          if (keys.filter((key) => key === k).length > 1) {
            throw new Error(`Key "${k}" exists multiple times.`);
          }

          const index = keys.indexOf(k);

          return keys.slice(index + 1)[0];
        },
      };
    } else if (v instanceof Object) {
      cacheObj[k] = {
        toKey: () => keyFn(k),
        ...cacheKeys({ prefix: keyFn(k), keyConfig: v as KeyConfig }),
      };
    } else {
      cacheObj[k] = {
        toKey: () => keyFn(k),
      };
    }
  }

  return cacheObj as CacheKeyHelperType<Prefixes, Config>;
}
