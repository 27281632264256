import { z } from 'zod';

export type PrimitiveZodTypes =
  | z.ZodString
  | z.ZodNumber
  | z.ZodDate
  | z.ZodArray<PrimitiveZodTypes>
  | z.ZodCatch<PrimitiveZodTypes>
  | z.ZodDefault<PrimitiveZodTypes>
  | z.ZodOptional<PrimitiveZodTypes>
  | z.ZodNullable<PrimitiveZodTypes>
  | z.ZodEffects<
      PrimitiveZodTypes,
      PrimitiveZodTypes | string | number,
      PrimitiveZodTypes | unknown
    >
  | z.ZodUnion<[PrimitiveZodTypes, PrimitiveZodTypes, ...PrimitiveZodTypes[]]>
  | z.ZodEnum<[string, string, ...string[]]>
  | z.ZodEffects<z.ZodOptional<z.ZodDate>, string, Date>
  | z.ZodLiteral<string>;

export type ZodObjectSchema = z.ZodObject<Record<string, PrimitiveZodTypes>, z.UnknownKeysParam>;

export function zodHasArray(schema: PrimitiveZodTypes): boolean {
  if (schema instanceof z.ZodArray) {
    return true;
  }

  if (schema instanceof z.ZodObject) {
    return Object.values(schema.shape).some((value) => zodHasArray(value as PrimitiveZodTypes));
  }

  if (schema instanceof z.ZodUnion) {
    return schema.options.some(zodHasArray);
  }

  if (schema instanceof z.ZodEnum) {
    return false;
  }

  if (schema instanceof z.ZodTuple) {
    return schema.items.some(zodHasArray);
  }

  if (schema instanceof z.ZodIntersection) {
    if ('left' in schema && 'right' in schema) {
      return schema.left
        ? zodHasArray(schema.left as PrimitiveZodTypes)
        : zodHasArray(schema.right as PrimitiveZodTypes);
    }
  }

  if (schema instanceof z.ZodEffects) {
    return zodHasArray(schema._def.schema);
  }

  if ('_def' in schema && typeof schema._def === 'object' && 'innerType' in schema._def) {
    return zodHasArray(schema._def.innerType);
  }

  return false;
}
