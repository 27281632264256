import type { NavigationGuardWithThis } from 'vue-router';
import { pinia } from '~/plugins/pinia';
import { useAuthenticationStore } from '~/stores/authentication';

export const isInfluencer = (redirectTo = 'dashboard'): NavigationGuardWithThis<unknown> => {
  return () => {
    const authenticationStore = useAuthenticationStore(pinia);

    if (!authenticationStore.isInfluencer) {
      return { name: redirectTo };
    }
  };
};
