import { expiresInToAt } from '@makeinfluence/ui/src/utils/expires';
import { useMutation } from '@tanstack/vue-query';
import { graphql } from 'gql.tada';
import { client } from '~/plugins/client';
import { useAuthenticationStore } from '~/stores/authentication';

export async function refreshToken(refreshToken: string) {
  const response = await client.request({
    document: graphql(`
      mutation RefreshToken($refreshToken: String!) {
        refreshToken(input: { refresh_token: $refreshToken }) {
          access_token
          expires_in
          refresh_token
          token_type
        }
      }
    `),
    variables: { refreshToken },
  });

  return response.refreshToken;
}

export function useRefreshTokenMutation() {
  const authenticationStore = useAuthenticationStore();

  const refreshTokenMutation = useMutation({
    mutationFn: refreshToken,
    onSuccess: (data) => {
      authenticationStore.setAuth({
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
        expiresAt: expiresInToAt(data.expires_in).toISOString(),
      });
    },
  });

  return refreshTokenMutation;
}
