import type { RouteRecordRaw } from 'vue-router';

export function getChatRoutes<Prefix extends string>(prefix?: Prefix) {
  return [
    {
      path: prefix ? 'chats' : '/chats',
      name: prefix ? (`${prefix}.chats` as const) : ('chats' as const),
      component: () => import('~/shared/chat/views/ChatsView'),
      children: [
        {
          path: ':channelUrl',
          name: prefix ? (`${prefix}.chat` as const) : ('chat' as const),
          component: () => import('~/shared/chat/views/ChatView'),
        },
      ],
    },
  ] satisfies RouteRecordRaw[];
}
