import { match } from 'ts-pattern';
import businessFavicon from '~/assets/favicons/business.png?url';
import creatorFavicon from '~/assets/favicons/creator.png?url';

export function changeFavicon(src: string) {
  const head = document.getElementsByTagName('HEAD')[0];
  const oldLink = document.getElementById('dynamic-favicon');
  const link = Object.assign(document.createElement('link'), {
    id: 'dynamic-favicon',
    rel: 'shortcut icon',
    href: src,
  });

  if (oldLink) {
    head?.removeChild(oldLink);
  }

  head?.appendChild(link);
}

export function setUserFavicon(userType: 'business' | 'influencer') {
  const favicon = match(userType)
    .with('business', () => businessFavicon)
    .with('influencer', () => creatorFavicon)
    .otherwise(() => creatorFavicon);

  changeFavicon(favicon);
}
