import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { ChevronDownIcon } from '@makeinfluence/icons/vue/outline';
import { tv } from 'tailwind-variants';
import { defineComponent, useAttrs } from 'vue';

export const miSelectOption = tv({
  base: 'flex w-full cursor-default gap-2 bg-white px-4 py-3 -outline-offset-2 hover:bg-gray-100 ui-active:bg-gray-200',
  variants: {
    active: {
      true: 'bg-gray-100',
      false: 'ui-active:bg-gray-100',
    },
  },
});

export const miSelectOptions = tv({
  base: 'max-h-60 overflow-y-auto overflow-x-hidden rounded-lg border border-gray-200 bg-white py-1 shadow-sm',
  variants: {
    inline: {
      true: 'static',
      false: 'absolute',
    },
  },
  defaultVariants: {
    inline: false,
  },
});

export const miSelectTrigger = tv({
  base: 'inline-flex max-w-full flex-shrink-0 list-none items-center gap-3 rounded-lg border border-solid text-lg shadow-sm transition focus-within:ring-4 focus:outline-none focus-visible:ring-4 disabled:border-gray-300 disabled:bg-gray-100 disabled:text-gray-300 disabled:placeholder:text-gray-300',
  variants: {
    state: {
      default:
        'border-gray-200 focus-within:border-black focus-within:ring-gray-900/10 focus-visible:border-black focus-visible:ring-gray-900/10',
      error: 'border-red-500 focus-within:ring-red-200 focus-visible:ring-red-200',
    },

    size: {
      xs: 'h-6 px-3 text-base',
      sm: 'h-8 px-3.5 text-base',
      base: 'h-10 px-4 text-lg',
      lg: 'h-11 px-5 text-lg',
    },
  },

  compoundVariants: [],

  defaultVariants: {
    state: 'default',
    size: 'base',
  },
});

export const miSelectDropdown = tv({
  base: 'mt-1 rounded-lg border border-gray-200 bg-white pl-0 transition group-focus-within:border-black',
});

export const MiSelect = defineComponent({
  name: 'MiSelect',
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const attrs = useAttrs();

    return () => (
      <Listbox {...attrs}>
        <ListboxButton
          class={miSelectTrigger({
            className: 'w-full justify-between',
          })}
        >
          <span>{props.label}</span>

          <ChevronDownIcon class="h-4 w-4" />
        </ListboxButton>

        <ListboxOptions
          class={miSelectOptions({
            className: 'mt-1',
          })}
        >
          {context.slots.default?.()}
        </ListboxOptions>
      </Listbox>
    );
  },
});

export const MiSelectOption = defineComponent({
  name: 'MiSelectOption',
  setup(_, context) {
    const attrs = useAttrs();

    return () => (
      <ListboxOption class={miSelectOption()} {...attrs}>
        {context.slots.default?.()}
      </ListboxOption>
    );
  },
});
