import '~/assets/styles/make-influence-icons.css';
import { createVuetify } from 'vuetify';
import { aliases, fa } from 'vuetify/iconsets/fa';

export const vuetify = createVuetify({
  icons: {
    defaultSet: 'fa',
    sets: {
      fa,
    },
    aliases,
  },
});
