import type { ExcludeEmptyObjects } from '@makeinfluence/types/src/generics';

export function ensureNonEmptyObject<Obj extends Record<string, unknown>>(obj: Obj | null) {
  if (obj === null) {
    throw new Error('Object is null');
  }

  if (Object.keys(obj).length === 0) {
    throw new Error('Object is empty');
  }

  return obj as ExcludeEmptyObjects<Obj>;
}
