import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed } from 'vue';

export type AuthenticationStore = {
  auth: {
    accessToken?: string;
    expiresAt?: string;
    refreshToken?: string;
    user?: {
      uuid?: string;
      userType?: string;
      businessUuid?: string;
      influencerUuid?: string;
      fullName: string;
      email: string;
      createdAt: string;
    };
    shadowLogin?: boolean;
  };
};

const initialState: AuthenticationStore = {
  auth: {
    accessToken: undefined,
    expiresAt: undefined,
    user: undefined,
    shadowLogin: undefined,
  },
};

export const useAuthenticationStore = defineStore('authentication', () => {
  const auth = useStorage<AuthenticationStore['auth']>('auth', initialState.auth);

  const isLoggedIn = computed(
    () => !!auth.value.accessToken && new Date(auth.value.expiresAt ?? '').getTime() > Date.now()
  );

  const isShadowLogin = computed(() => auth.value.shadowLogin);
  const isBusiness = computed(() => auth.value.user?.userType === 'App\\Models\\Business');
  const isInfluencer = computed(() => auth.value.user?.userType === 'App\\Models\\Influencer');
  const isModerator = computed(() => auth.value.user?.userType === 'App\\Models\\Moderator');
  const userType = computed(() => {
    if (isBusiness.value) return 'business';
    if (isInfluencer.value) return 'influencer';

    return null;
  });

  const businessUuid = computed(() => auth.value.user?.businessUuid ?? '');
  const influencerUuid = computed(() => auth.value.user?.influencerUuid ?? '');
  const userUuid = computed(() => auth.value.user?.uuid ?? '');

  function setAuth(newAuth: AuthenticationStore['auth']) {
    auth.value = {
      ...auth.value,
      ...newAuth,
    };
  }

  function clearAuth() {
    auth.value = initialState.auth;
  }

  return {
    auth,
    businessUuid,
    clearAuth,
    influencerUuid,
    isBusiness,
    isInfluencer,
    isLoggedIn,
    isModerator,
    isShadowLogin,
    setAuth,
    userType,
    userUuid,
  };
});
