import { CloseIcon } from '@makeinfluence/icons/vue/outline';
import ms from 'ms';
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { useRouter } from 'vue-router';
import { useDialog } from '../../composables/use-dialog';
import { circleButton, dialogBase, dialogContent, dialogOutside } from './dialog.styles';
import { onKeyStroke } from '@vueuse/core';

export const MiDialog = defineComponent({
  name: 'MiDialog',
  inheritAttrs: false,
  props: {
    returnTo: {
      type: [Object, String] as PropType<RouteLocationRaw>,
      required: false,
      default: undefined,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:close': () => true,
  },
  setup(props, context) {
    const router = useRouter();

    const [dialogRefs, dialogMethods] = useDialog({
      isOpen: computed(() => props.isOpen),
      animationOptions: { duration: ms('200ms'), easing: 'ease-in-out' },
      dialogKeyframes: [{ opacity: 0 }, { opacity: 1 }],
      contentKeyframes: [
        { opacity: 0, transform: 'translateY(-50px) scale(0.8)' },
        { opacity: 1, transform: 'translateY(0) scale(1)' },
      ],
      onClosing: () => {
        if (!props.returnTo) return;

        router.replace(props.returnTo);
      },
      onClose: () => {
        context.emit('update:close');
      },
    });

    onKeyStroke('Escape', (event) => {
      if (!props.isOpen) return;

      event.preventDefault();

      dialogMethods.handleClose();
    });

    return () => (
      <dialog ref={dialogRefs.dialog} class={dialogBase()}>
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: Outside area is not focusable */}
        <div
          class={dialogOutside()}
          onMousedown={(event) => {
            // Prevent closing the dialog when clicking on the dialog content
            if (event.target !== event.currentTarget) return;

            dialogMethods.handleClose(event);
          }}
        >
          <div ref={dialogRefs.content} class={dialogContent()} {...context.attrs}>
            <header class="sticky top-0 z-10 flex items-center border-b border-gray-200 bg-white px-3 py-2">
              <div class="flex flex-1 justify-between">
                <div class="flex flex-wrap items-center">
                  {typeof context.slots.topLeft === 'function' ? context.slots.topLeft() : null}
                </div>

                <div class="flex flex-wrap items-center">
                  {typeof context.slots.topRight === 'function' ? context.slots.topRight() : null}
                </div>
              </div>

              {typeof context.slots.closeButton === 'function' ? (
                context.slots.closeButton()
              ) : (
                <button
                  class={circleButton()}
                  type="button"
                  onClick={() => {
                    context.emit('update:close');

                    dialogMethods.handleClose();
                  }}
                >
                  <CloseIcon class="size-full" />
                </button>
              )}
            </header>

            {context.slots.default?.({
              close: dialogMethods.handleClose,
            })}
          </div>
        </div>
      </dialog>
    );
  },
});
