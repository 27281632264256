import { match } from 'ts-pattern';
import type { NavigationGuardWithThis } from 'vue-router';
import { fetchStripeDetails } from '~/router/fetch-stripe-details.business';

export const whenHasActiveSubscription = (
  redirectTo = 'dashboard'
): NavigationGuardWithThis<unknown> => {
  return async (_to, _from, next) => {
    const { hasActiveSubscription, hasStripeId } = await fetchStripeDetails();

    match({ hasActiveSubscription, hasStripeId })
      .with({ hasActiveSubscription: false, hasStripeId: false }, () => {
        next();
      })
      .with({ hasActiveSubscription: false, hasStripeId: true }, () => {
        next();
      })
      // we think this is impossible, but adding just in case
      .with({ hasActiveSubscription: true, hasStripeId: false }, () => {
        next({ name: redirectTo });
      })
      .with({ hasActiveSubscription: true, hasStripeId: true }, () => {
        next({ name: redirectTo });
      })
      .exhaustive();
  };
};
