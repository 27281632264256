import type { V1Schema } from '@makeinfluence/graphql-schema/types';
import { ensureNonEmptyObject } from '@makeinfluence/ui/src/utils/ensure-non-empty-object';
import { useQuery } from '@tanstack/vue-query';
import { debouncedRef } from '@vueuse/core';
import type { MaybeRef, PropType } from 'vue';
import { computed, defineComponent, ref, unref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { searchKeys } from '~/cache.keys';
import { MiCombobox, comboboxModelValueSchema } from '~/components/MiCombobox';
import { influencersOrderByEnum } from '~/enums';
import { graphql } from '~/graphql';
import { client } from '~/plugins/client';

export function useInfluencerSearchQuery(
  search: MaybeRef<string>,
  options: Omit<V1Schema.QueryInfluencersArgs, 'search'> & {
    enabled?: MaybeRef<boolean>;
  } = {}
) {
  const influencersQuery = useQuery({
    enabled: options.enabled ?? true,
    keepPreviousData: true,
    queryKey: computed(() => {
      return searchKeys.influencer.toKeyWithArgs({
        order: 'desc',
        orderBy: influencersOrderByEnum.enum.recommended,
        limit: 20,
        page: 1,
        search: unref(search),
        ...options,
      });
    }),
    queryFn: async ({ signal, queryKey }) => {
      const variables = searchKeys.influencer.toArgsFromKey(queryKey);

      const response = await client.request({
        document: graphql(`
          query InfluencerSearchQuery(
            $search: String
            $orderBy: String
            $order: String
            $limit: Int
            $page: Int
          ) {
            influencers(
              search: $search
              orderBy: $orderBy
              order: $order
              limit: $limit
              page: $page
            ) {
              ... on InfluencersPaginated {
                response {
                  uuid
                  user {
                    full_name
                  }
                }
              }
            }
          }
        `),
        variables,
        signal,
      });

      return ensureNonEmptyObject(response.influencers);
    },
  });

  return influencersQuery;
}

export const InfluencerSearch = defineComponent({
  name: 'InfluencerSearch',
  props: {
    id: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Array, null] as PropType<string | string[] | null>,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    custom: {
      type: Object as PropType<{
        options: Array<{ label: string; value: string }>;
        isFetching: boolean;
      } | null>,
      required: false,
      default: null,
    },
    searchQuery: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: {
    'update:modelValue': comboboxModelValueSchema.parse,
    'update:searchQuery': (_value: string) => true,
  },
  setup(props, context) {
    const { t } = useI18n();

    const search = ref<string>(props.searchQuery);

    watch(
      () => props.searchQuery,
      (newValue) => {
        search.value = newValue;
      }
    );

    const influencersSearchQuery = useInfluencerSearchQuery(debouncedRef(search, 300), {
      enabled: props.custom === null,
    });

    return () => {
      const options =
        props.custom !== null
          ? props.custom.options.filter((option) =>
              option.label.toLowerCase().includes(search.value.toLowerCase())
            )
          : influencersSearchQuery.data.value?.response?.map((influencer) => ({
              label: influencer?.user?.full_name ?? '',
              value: influencer?.uuid ?? '',
            })) ?? [];

      return (
        <MiCombobox
          id={props.id}
          name={props.name}
          modelValue={props.modelValue}
          onUpdate:modelValue={(value) => {
            context.emit('update:modelValue', value);
          }}
          nullable
          placeholder={t('words--search_creators')}
          nothingFoundText={t('words--no_creators_found')}
          options={options}
          isLoading={props.custom?.isFetching ?? influencersSearchQuery.isFetching.value}
          searchQuery={search.value}
          onUpdate:searchQuery={(value) => {
            search.value = value;

            context.emit('update:searchQuery', value);
          }}
          inline={props.inline}
          multiple={props.multiple}
        />
      );
    };
  },
});
