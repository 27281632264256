import { pinia } from '~/plugins/pinia';
import { useAuthenticationStore } from '~/stores/authentication';
import type { NavigationGuardWithThis } from 'vue-router';

export const notAuthenticated = (redirectTo = 'dashboard'): NavigationGuardWithThis<unknown> => {
  return () => {
    const authenticationStore = useAuthenticationStore(pinia);

    if (authenticationStore.auth.accessToken) {
      return { name: redirectTo };
    }
  };
};
