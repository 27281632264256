import { AlertMessage } from '@makeinfluence/ui/src/components/AlertMessage';
import { MiDialog } from '@makeinfluence/ui/src/components/dialogs/MiDialog';
import { MiButton, handleButtonState } from '@makeinfluence/ui/src/components/new-app/mi-button';
import { useBreakpoint } from '@makeinfluence/ui/src/composables/use-breakpoint';
import { emptySchemaKeys } from '@makeinfluence/ui/src/utils/empty-keys';
import { ensureNonEmptyObject } from '@makeinfluence/ui/src/utils/ensure-non-empty-object';
import { useQuery } from '@tanstack/vue-query';
import invariant from 'tiny-invariant';
import { match } from 'ts-pattern';
import { computed, defineComponent, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import type { RouteLocationRaw } from 'vue-router';
import { useRoute, useRouter } from 'vue-router';
import { z } from 'zod';
import { searchKeys } from '~/cache.keys';
import { BrandSearch } from '~/components/BrandSearch';
import { InfluencerSearch } from '~/components/InfluencerSearch';
import { useCreateChatMutation } from '~/composables/use-create-chat-mutation';
import { useZodParams } from '~/composables/use-zod-params';
import { influencersOrderByEnum } from '~/enums';
import { graphql } from '~/graphql';
import { client } from '~/plugins/client';
import { useAuthenticationStore } from '~/stores/authentication';
import { useFeedbackStore } from '~/stores/feedback';

export const newChatSchema = z.object({
  selectedBrandId: z.string().nullish(),
  selectedInfluencerId: z.string().nullish(),
  brandSearch: z.string().nullish(),
  influencerSearch: z.string().nullish(),
});

export const NewChatDialog = defineComponent({
  name: 'NewChatDialog',
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const authenticationStore = useAuthenticationStore();
    const feedbackStore = useFeedbackStore();
    const newChatParams = useZodParams({
      global: true,
      enabled: computed(() => route.query.dialog === 'new-chat'),
      schema: newChatSchema,
    });
    const isDesktop = useBreakpoint('lg');
    const createChatMutation = useCreateChatMutation();

    const brandsQuery = useQuery({
      enabled: computed(() => {
        return authenticationStore.isInfluencer;
      }),
      keepPreviousData: true,
      queryKey: computed(() => {
        return searchKeys.brand.toKeyWithArgs({
          search: newChatParams.brandSearch,
        });
      }),
      queryFn: async ({ signal, queryKey }) => {
        const variables = searchKeys.brand.toArgsFromKey(queryKey);

        const response = await client.request({
          document: graphql(`
            query NewChatBusinesses($search: String) {
              businesses(search: $search, page: 1, limit: 20) {
                ... on BusinessesPaginated {
                  response {
                    user {
                      uuid
                      full_name
                    }
                    display_name
                  }
                }
              }
            }
          `),
          variables,
          signal,
        });

        return response.businesses;
      },
    });
    const influencersQuery = useQuery({
      enabled: computed(() => {
        return authenticationStore.isBusiness;
      }),
      keepPreviousData: true,
      queryKey: computed(() => {
        return searchKeys.influencer.toKeyWithArgs({
          search: newChatParams.influencerSearch,
          orderBy: influencersOrderByEnum.enum.recommended,
          order: 'asc',
        });
      }),
      queryFn: async ({ signal, queryKey }) => {
        const variables = searchKeys.influencer.toArgsFromKey(queryKey);

        const response = await client.request({
          document: graphql(`
            query NewChatInfluencers($search: String, $orderBy: String, $order: String) {
              influencers(search: $search, orderBy: $orderBy, order: $order, page: 1, limit: 20) {
                ... on InfluencersPaginated {
                  response {
                    user {
                      uuid
                      full_name
                    }
                  }
                }
              }
            }
          `),
          variables,
          signal,
        });

        return ensureNonEmptyObject(response.influencers);
      },
    });

    function clearQueryParams() {
      return {
        ...route.query,
        ...emptySchemaKeys(newChatSchema),
        dialog: undefined,
      };
    }

    return () => (
      <MiDialog
        isOpen={route.query.dialog === 'new-chat'}
        onUpdate:close={() => {
          if (route.query.dialog) {
            router.replace({
              query: clearQueryParams(),
            });
          }
        }}
        class="max-w-md"
      >
        <form
          class="p-6"
          onSubmit={(event) => {
            event.preventDefault();

            const userId = newChatParams.selectedBrandId || newChatParams.selectedInfluencerId;

            invariant(userId, 'User ID is required');

            createChatMutation.mutate(
              {
                user_uuid: userId,
              },
              {
                onSuccess: async (data) => {
                  const to = {
                    name: 'chat',
                    params: {
                      channelUrl: data.channel_url,
                    },
                  } satisfies RouteLocationRaw;

                  if (isDesktop.value) {
                    await router.replace({
                      ...to,
                      query: clearQueryParams(),
                    });
                  } else {
                    await router.replace({
                      query: clearQueryParams(),
                    });
                    await nextTick();
                    await router.push(to);
                  }
                },
                onError: () => {
                  feedbackStore.triggerSnackbar({
                    message: t('words--chat_creation_failed').toString(),
                    type: 'error',
                  });
                },
              }
            );
          }}
        >
          <div class="text-center text-header-l">{t('words--new_chat')}</div>

          <div class="pt-4">
            {match({
              isBusiness: authenticationStore.isBusiness,
              isInfuencer: authenticationStore.isInfluencer,
            })
              .with({ isBusiness: true }, () => (
                <InfluencerSearch
                  name="influencer"
                  onUpdate:searchQuery={(value) => {
                    newChatParams.influencerSearch = value;
                  }}
                  modelValue={newChatParams.selectedInfluencerId}
                  onUpdate:modelValue={(value) => {
                    newChatParams.selectedInfluencerId =
                      newChatSchema.shape.selectedInfluencerId.parse(value);

                    newChatParams.selectedBrandId = undefined;
                  }}
                  inline
                  custom={{
                    isFetching: influencersQuery.isFetching.value,
                    options:
                      influencersQuery.data.value?.response?.map((influencer) => ({
                        label: influencer?.user?.full_name ?? '',
                        value: influencer?.user?.uuid ?? '',
                      })) ?? [],
                  }}
                />
              ))
              .with({ isInfuencer: true }, () => (
                <BrandSearch
                  name="brand"
                  onUpdate:searchQuery={(value) => {
                    newChatParams.brandSearch = value;
                  }}
                  modelValue={newChatParams.selectedBrandId}
                  onUpdate:modelValue={(value) => {
                    newChatParams.selectedBrandId =
                      newChatSchema.shape.selectedBrandId.parse(value);

                    newChatParams.selectedInfluencerId = undefined;
                  }}
                  inline
                  custom={{
                    isFetching: brandsQuery.isFetching.value,
                    options:
                      brandsQuery.data.value?.response?.map((brand) => ({
                        label: `${brand?.display_name} (${brand?.user?.full_name})`,
                        value: brand?.user?.uuid ?? '',
                      })) ?? [],
                  }}
                />
              ))
              .otherwise(() => (
                <AlertMessage type="error">{t('words--invalid_user_type')}</AlertMessage>
              ))}
          </div>

          <div class="pt-4">
            <MiButton
              type="submit"
              class="w-full"
              disabled={createChatMutation.isLoading.value}
              state={handleButtonState({
                loading: createChatMutation.isLoading.value,
              })}
            >
              {t('words--start_chat')}
            </MiButton>
          </div>
        </form>
      </MiDialog>
    );
  },
});
